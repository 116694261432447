import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { CookieService } from 'ngx-cookie-service';

import { ConfigService } from '../../../services/config.service';
import { LibraryService } from '../../../services/library.service';
import { LoginService } from '../../../services/login.service';
import { LoadingService } from '../../../services/loading.service';
import { DialogService } from '../../../services/dialog.service';

import { CreationSpaceSearchPipe } from '../../../pipes/creation-space-search.pipe';

import { Content } from '../../../structures/content';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-creation-space',
    templateUrl: './creation-space.component.html',
    styleUrls: ['./creation-space.component.scss']
})
export class CreationSpaceComponent implements OnInit {
    subscriptions = new Subscription();

    drafts: any;
    search: string;
    searchTerm: string;

    expandedActivity: boolean;
    expandedAssembly: boolean;
    expandedUpdate: boolean;

    constructor(
        private cookieService: CookieService,
        private configService: ConfigService,
        private loginService: LoginService,
        private libraryService: LibraryService,
        private dialogService: DialogService,
        private loadingService: LoadingService
    ) {}

    ngOnInit() {
        this.initContent();
        this.expandedActivity = true;
        this.expandedAssembly = true;
        this.expandedUpdate = true;
        this.subscriptions.add(
            this.libraryService.addedDraft.subscribe((content: Content) => {
                if (content.desiredPublicationMode === 'new' && !content.level) {
                    this.addActivity(content);
                } else if (content.desiredPublicationMode === 'new' && content.level) {
                    this.addAssembly(content);
                } else if (content.desiredPublicationMode === 'update') {
                    this.addUpdate(content);
                }
            })
        );
        this.subscriptions.add(
            this.libraryService.updatedDraft.subscribe((content: Content) => {
                if (content.desiredPublicationMode === 'new' && !content.level) {
                    this.updateActivity(content);
                } else if (content.desiredPublicationMode === 'new' && content.level) {
                    this.updateAssembly(content);
                } else if (content.desiredPublicationMode === 'update') {
                    this.updateUpdate(content);
                }
            })
        );
        this.subscriptions.add(
            this.libraryService.deletedDraft.subscribe((content: Content) => {
                if (content.desiredPublicationMode === 'new' && !content.level) {
                    this.removeActivity(content);
                } else if (content.desiredPublicationMode === 'new' && content.level) {
                    this.removeAssembly(content);
                } else if (content.desiredPublicationMode === 'update') {
                    this.removeUpdate(content);
                }
            })
        );
    }

    initContent() {
        this.loadingService.startLoading('creationSpace', 'getDrafts');
        this.subscriptions.add(
            this.libraryService.getDrafts().subscribe(
                (data: Array<Content>) => {
                    this.drafts = data;
                    this.loadingService.stopLoading('creationSpace', 'getDrafts');
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('creationSpace', 'getDrafts');
                }
            )
        );
    }

    applySearch() {
        this.search = this.searchTerm;
    }

    cancelSearch() {
        this.search = '';
        this.searchTerm = '';
    }

    getActivityCount() {
        if (this.drafts && this.drafts.activities) {
            const searchPipe = new CreationSpaceSearchPipe();
            return searchPipe.transform(this.drafts.activities, this.search).length;
        }
    }

    getAssemblyCount() {
        if (this.drafts && this.drafts.assemblies) {
            const searchPipe = new CreationSpaceSearchPipe();
            return searchPipe.transform(this.drafts.assemblies, this.search).length;
        }
    }

    getUpdateCount() {
        if (this.drafts && this.drafts.update) {
            const searchPipe = new CreationSpaceSearchPipe();
            return searchPipe.transform(this.drafts.update, this.search).length;
        }
    }

    openContentSelection() {
        this.subscriptions.add(
            this.dialogService.openContentSelection().subscribe((data: string) => {
                switch (data) {
                    case 'mass_activity':
                    case 'mass_assembly': {
                        this.dialogService.openMassImport(data);
                        break;
                    }
                    case 'mass_content_update': {
                        this.dialogService.openMassUpdate();
                        break;
                    }
                    case 'h5p':
                    case 'assembly':
                    case 'import':
                    case 'url':
                    case 'partenariat':
                    case 'lti':
                    case 'manuel':
                    case 'n4s':
                    case 'devoir':
                    case 'presentiel':
                    case 'quiz': {
                        const content: Content = {
                            status: 'draft',
                            localStructure: '',
                            sites: [],
                            enabled: true,
                            hasprice: 0,
                            price: 0,
                            pricetxt: '',
                            hidden: false,
                            forceStatus: true,
                            is_training_of_teachers: false,
                            visible_for_all: true,
                            copyright: this.loginService.getUser().localStructure
                                ? this.loginService.getUser().localStructure
                                : 'UIAF',
                            ucode: '',
                            author: this.loginService.getUser().id,
                            title: '',
                            description: '',
                            duration: '',
                            provider: '',
                            taxonomy: '',
                            domains: [],
                            keywords: [],
                            tableOfContents: false
                        };

                        switch (data) {
                            case 'assembly': {
                                content.level = '';
                                content.type = '';
                                break;
                            }
                            case 'import': {
                                content.category = '';
                                content.type = 'fichier';
                                break;
                            }
                            case 'url': {
                                content.category = '';
                                content.type = 'url';
                                content.url = '';
                                break;
                            }
                            case 'partenariat': {
                                content.category = '';
                                content.type = 'partenariat';
                                content.url = '';
                                break;
                            }
                            case 'lti': {
                                content.category = '';
                                content.type = 'lti';
                                content.lti_url = '';
                                content.lti_key = '';
                                content.lti_params = '';
                                content.lti_resourcekey = '';
                                break;
                            }
                            case 'manuel': {
                                content.category = 'ressource';
                                content.type = this.configService.getConfig().nathanNationalCreation
                                    ? 'nathan'
                                    : 'hachette';
                                content.code_ressource = '';
                                content.code_uai = '';
                                break;
                            }
                            case 'devoir':
                                content.category = 'devoir';
                                content.type = 'devoir';
                                break;
                            case 'presentiel': {
                                content.category = '';
                                content.type = 'presentiel';
                                break;
                            }
                            case 'n4s': {
                                content.category = 'positionnement';
                                content.type = 'n4s';
                                break;
                            }
                            case 'quiz': {
                                content.category = '';
                                content.type = 'quiz';
                                break;
                            }
                            case 'h5p': {
                                content.category = '';
                                content.type = 'h5pactivity';
                                break;
                            }
                        }
                        this.dialogService.openContentCreation(content);
                    }
                }
            })
        );
    }

    addActivity(data: Content) {
        this.drafts.activities.push(data);
    }

    updateActivity(data: Content) {
        const index = this.drafts.activities.findIndex((draft: Content) => draft.id === data.id);
        if (index !== -1) {
            this.drafts.activities[index] = data;
        }
    }

    removeActivity(data: Content) {
        const index = this.drafts.activities.findIndex((draft: Content) => draft.id === data.id);
        if (index !== -1) {
            this.drafts.activities.splice(index, 1);
        }
    }

    addAssembly(data: Content) {
        if (!data.parentid) {
            this.drafts.assemblies.push(data);
        }
    }

    updateAssembly(data: Content) {
        const index = this.drafts.assemblies.findIndex((draft: Content) => draft.id === data.id);
        if (index !== -1) {
            this.drafts.assemblies[index] = data;
        }
    }

    removeAssembly(data: Content) {
        const index = this.drafts.assemblies.findIndex((draft: Content) => draft.id === data.id);
        if (index !== -1) {
            this.drafts.assemblies.splice(index, 1);
        }
    }

    addUpdate(data: Content) {
        this.drafts.update.push(data);
    }

    cloneUpdate(data: Content) {
        if (data.level) {
            this.addAssembly(data);
        } else {
            this.addActivity(data);
        }
    }

    updateUpdate(data: Content) {
        const index = this.drafts.update.findIndex((draft: Content) => draft.id === data.id);
        if (index !== -1) {
            this.drafts.update[index] = data;
        }
    }

    removeUpdate(data: Content) {
        const index = this.drafts.update.findIndex((draft: Content) => draft.id === data.id);
        if (index !== -1) {
            this.drafts.update.splice(index, 1);
        }
    }

    emitFoldAll() {
        this.cancelSearch();
        this.libraryService.emitFoldAll();
    }

    isLoading() {
        return this.loadingService.isLoading('creationSpace');
    }
}
