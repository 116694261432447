<mat-expansion-panel
    #libraryEntry
    class="library-entry"
    (click)="entryClickHandler()"
    [ngClass]="{ disabled: !entryContent.enabled && !isArchiveView }"
    [expanded]="isParentOf() || opened"
    [hideToggle]="true"
    (opened)="toggleIcon()"
    (closed)="toggleIcon()"
    [dndDraggable]="entryContent"
    (dndStart)="startDrag()"
    (dndEnd)="endDrag()"
    *ngIf="!isCatalogView"
>
    <mat-expansion-panel-header
        class="library-entry-header"
        [ngClass]="{ highlighted: entryContent.id === parentOfId }"
        [collapsedHeight]="'50px'"
        [expandedHeight]="'50px'"
    >
        <div
            class="library-entry-header-content  {{ entryContent.report?.status }}"
            #libraryEntryHeaderContent
            [ngClass]="{ 'report-view': isReportView }"
        >
            <div
                class="library-entry-title"
                [tooltip]="getTitleTooltip()"
                tooltipPosition="above-right"
            >
                <div class="order-number" *ngIf="entryContent.orderNumber">
                    {{ entryContent.orderNumber }}
                </div>
                <div [ngClass]="getIcon()" (click)="selectContent($event)">
                    <div class="national-content" *ngIf="isNationalContent()"></div>
                </div>
                <div class="title">
                    {{ entryContent.title }}
                    <span *ngIf="entryContent.type" class="title__entry-type">
                        ({{ entryContent.type === 'quiz' ? 'test' : entryContent.type }})
                    </span>
                    <span *ngIf="isPreviousVersionView" class="version">
                        (v{{ entryContent.version }})
                    </span>
                </div>
            </div>

            <div class="report-date" *ngIf="isReportView">
                <div>Signalement créé le :</div>
                <div>{{ this.entryContent.report.timecreated.toLocaleString() | dates }}</div>
            </div>

            <div
                class="library-entry-icons"
                #libraryEntryIcons
                [style.gridTemplateColumns]="
                    'fit-content(' + numberOfHoverIconsCanFit * 30 + 'px) auto'
                "
            >
                <div class="hover-icons" #hoverIcons>
                    <span
                        class="icon-voir"
                        *ngIf="isVisibleIconForAction('openActivity')"
                        (click)="openActivity($event)"
                        [tooltip]="'Voir l\'activité'"
                    ></span>
                    <span
                        class="icon-Favoris"
                        *ngIf="isVisibleIconForAction('addToFavorites')"
                        (click)="addToFavorites($event)"
                        [tooltip]="'Ajouter aux favoris'"
                    ></span>
                    <span
                        class="icon-Retirerdesfavoris"
                        *ngIf="isVisibleIconForAction('removeFromFavorites')"
                        (click)="removeFromFavorites($event)"
                        [tooltip]="'Retirer des favoris'"
                    ></span>
                    <span
                        class="icon-Voirplus"
                        tooltip="Voir toutes les actions"
                        [more-actions]="this"
                        [more-actions-origin]="'library'"
                        [more-actions-class]="className"
                        (click)="$event.stopImmediatePropagation()"
                    ></span>
                </div>

                <div class="visible-icons" #visibleIcons>
                    <ng-container *ngIf="isVisibleIcon('note-numeric')">
                        <div class="note-numeric">
                            {{ getFormattedNote(entryContent.note_avg) }}
                        </div>
                        <div
                            class="icon-thumbup-plein average-note"
                            [ngStyle]="{
                                'background-image': getBackgroundGradient(entryContent.note_avg)
                            }"
                            (click)="rateActivity($event)"
                            [tooltip]="getNoteTooltip(entryContent)"
                        ></div>
                    </ng-container>
                    <span
                        class="icon-nouveau"
                        *ngIf="isReportView && showReportNewIcon()"
                        [tooltip]="getReportNewIconTooltip()"
                    ></span>
                    <span
                        class="icon-message"
                        *ngIf="isReportView && isNationalAdmin()"
                        tooltip="Envoyer un email à l'auteur du signalement"
                        (click)="sendMailToReportAuthor()"
                    ></span>
                    <span
                        class="icon-Timer-cercle"
                        *ngIf="isVisibleIcon('icon-Timer-cercle')"
                        [tooltip]="getFormatedDuration()"
                    >
                        <span
                            class="icon-Warning"
                            *ngIf="isIncompleteDuration() && showDuration()"
                        ></span>
                    </span>

                    <span
                        class="icon-nouveau"
                        *ngIf="isVisibleIcon('icon-nouveau')"
                        tooltip="Créé le {{ entryContent.timecreated | dates }}"
                    ></span>
                    <span
                        class="icon-misajour"
                        *ngIf="isVisibleIcon('icon-misajour')"
                        tooltip="Modifié le {{ entryContent.timemodified | dates }}"
                    ></span>
                    <span
                        class="icon-OrdoOn"
                        *ngIf="isVisibleIcon('icon-OrdoOn')"
                        [tooltip]="'Contenu ordonné'"
                    ></span>
                    <div class="prices-div" *ngIf="isVisibleIcon('price')">
                        <span class="text-price" *ngIf="entryContent.hasprice === 1">
                            {{ 'HT ' + entryContent.price }}
                        </span>
                        <span class="text-price small" *ngIf="entryContent.hasprice === 2">
                            A assigner via le contenu parent
                        </span>
                        <span class="text-price small" *ngIf="entryContent.hasprice === 3">
                            {{ entryContent.pricetxt }}
                        </span>

                        <span class="icon-Contenupayant" tooltip="Contenu payant"></span>
                    </div>
                    <span
                        class="icon-Favoris"
                        *ngIf="isVisibleIcon('icon-Favoris')"
                        [tooltip]="'Contenu favori'"
                    ></span>
                    <span
                        class="icon-userinconnu"
                        *ngIf="isVisibleIcon('icon-userinconnu')"
                        [tooltip]="'Contenu créé dont je suis l\'auteur'"
                    ></span>
                    <div
                        class="icon-troispoints"
                        *ngIf="isVisibleIcon('icon-troispoints')"
                        (click)="forceGetChildren($event)"
                        [tooltip]="'Voir l\'ensemble des contenus'"
                    ></div>
                    <div
                        class="icon-down"
                        *ngIf="isVisibleIcon('icon-down')"
                        [ngClass]="{ open: opened }"
                    ></div>

                    <!-- A RANGER -->

                    <div *ngIf="!isAssembly()" class="blank"></div>
                </div>
            </div>
        </div>
    </mat-expansion-panel-header>
    <ng-template
        matExpansionPanelContent
        *ngIf="entryContent.level && entryContent.level !== 'activity'"
    >
        <div
            *ngIf="parentOfMode"
            class="icon-troispoints"
            [ngClass]="getChildrenContentLevel()"
        ></div>
        <app-library-entry
            *ngFor="let child of entryContent.children"
            [entryContent]="child"
            [isArchiveView]="isArchiveView"
            [isReportView]="isReportView"
            [isDisabledView]="isDisabledView"
            [isPreviousVersionView]="isPreviousVersionView"
            [parentOfMode]="parentOfMode"
            [parentOfId]="parentOfId"
            class="expansion-panel-child"
            (parentOf)="emitParentOf($event)"
        ></app-library-entry>
        <div
            *ngIf="parentOfMode"
            class="icon-troispoints"
            [ngClass]="getChildrenContentLevel()"
        ></div>
    </ng-template>
</mat-expansion-panel>
<div class="library-entry catalog" [ngClass]="{ new: entryContent.new }" *ngIf="isCatalogView">
    <div class="banner" *ngIf="entryContent.new && !entryContent.forced_stripe">NOUVEAU</div>
    <div
        class="banner"
        [ngStyle]="{
            'background-color': entryContent.forced_stripe_color,
            'font-size': getStripeFontSize()
        }"
        *ngIf="entryContent.forced_stripe"
    >
        {{ entryContent.forced_stripe_text }}
    </div>

    <div class="visuel">
        <img [src]="entryContent.visuel" *ngIf="entryContent.visuel" />
    </div>
    <div class="information">
        <div class="title-container">
            <div [ngClass]="getIcon()">
                <div class="national-content" *ngIf="isNationalContent()"></div>
            </div>
            <div class="title">
                {{ getCatalogTitle() }}
            </div>
        </div>
        <div class="icons-container">
            <div>
                <div class="icon-Timer-cercle"></div>
                <div class="time">{{ getCatalogDuration() }}</div>
            </div>
            <div>
                <div
                    class="icon-guide"
                    *ngIf="canViewGuide()"
                    (click)="viewGuide($event)"
                    tooltip="Télécharger le guide"
                    tooltipPosition="below"
                ></div>
                <div
                    class="icon-voir-details"
                    *ngIf="getCatalogInformation()"
                    [tooltip]="getCatalogInformation()"
                    tooltipPosition="below"
                ></div>
                <div
                    class="icon-voir"
                    tooltip="Voir dans la bibliothèque"
                    tooltipPosition="below"
                    (click)="viewInLibrary()"
                ></div>
            </div>
        </div>
    </div>
</div>
