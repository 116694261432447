import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';

import { ActivityRatingComponent } from '../dialogs/activity-rating/activity-rating.component';
import { AddDisponibilityComponent } from '../dialogs/add-disponibility/add-disponibility.component';
import { AddValidatorComponent } from '../dialogs/add-validator/add-validator.component';
import { AdvancedAssignationComponent } from '../dialogs/advanced-assignation/advanced-assignation.component';
import { ApproveContentComponent } from '../dialogs/approve-content/approve-content.component';
import { AssemblyConfirmationComponent } from '../dialogs/assembly-confirmation/assembly-confirmation.component';
import { ConfirmationComponent } from '../dialogs/confirmation/confirmation.component';
import { ContentCreationComponent } from '../dialogs/content-creation/content-creation.component';
import { ContentDetailsComponent } from '../dialogs/content-details/content-details.component';
import { ContentHistoryComponent } from '../dialogs/content-history/content-history.component';
import { ContentSelectionComponent } from '../dialogs/content-selection/content-selection.component';
import { CopyMediaComponent } from '../dialogs/copy-media/copy-media.component';
import { CreateDomainComponent } from '../dialogs/create-domain/create-domain.component';
import { CreateProviderComponent } from '../dialogs/create-provider/create-provider.component';
import { DelegateValidationComponent } from '../dialogs/delegate-validation/delegate-validation.component';
import { DevoirConfirmationComponent } from '../dialogs/devoir-confirmation/devoir-confirmation.component';
import { UserTrackingComponent } from '../dialogs/user-tracking/user-tracking.component';
import { GroupTrackingComponent } from '../dialogs/group-tracking/group-tracking.component';
import { EditAuthorComponent } from '../dialogs/edit-author/edit-author.component';
import { ErrorComponent } from '../dialogs/error/error.component';
import { ExchangeComponent } from '../dialogs/exchange/exchange.component';
import { ExportCatalogComponent } from '../dialogs/export-catalog/export-catalog.component';
import { LibraryConfirmationComponent } from '../dialogs/library-confirmation/library-confirmation.component';
import { MassImportComponent } from '../dialogs/mass-import/mass-import.component';
import { MassUpdateComponent } from '../dialogs/mass-update/mass-update.component';
import { PersonnalizeGroupAssignmentComponent } from '../dialogs/personnalize-group-assignment/personnalize-group-assignment.component';
import { RedirectMediaComponent } from '../dialogs/redirect-media/redirect-media.component';
import { RejectContentComponent } from '../dialogs/reject-content/reject-content.component';
import { ReportActivityErrorComponent } from '../dialogs/report-activity-error/report-activity-error.component';
import { RevealAnonymousDevoirConfirmationComponent } from '../dialogs/reveal-anonymous-devoir-confirmation/reveal-anonymous-devoir-confirmation.component';
import { SelectGroupReporting } from '../dialogs/select-group-reporting/select-group-reporting.component';
import { SelectFIFCComponent } from '../dialogs/select-fi-fc/select-fi-fc.component';
import { SelectPublicationComponent } from '../dialogs/select-publication/select-publication.component';
import { SelectValidatorComponent } from '../dialogs/select-validator/select-validator.component';
import { UploadDevoirCorrectionComponent } from '../dialogs/upload-devoir-correction/upload-devoir-correction.component';
import { ViewActivityErrorComponent } from '../dialogs/view-activity-error/view-activity-error.component';
import { WarningComponent } from '../dialogs/warning/warning.component';

import { LibraryService } from './library.service';
import { UserService } from './user.service';
import { LoginService } from './login.service';

import { Content } from '../structures/content';
import { Domain } from '../structures/domain';
import { Assignment } from '../structures/assignment';
import { User } from '../structures/user';
import { Group } from '../structures/group';
import { InfoComponent } from '../dialogs/info/info.component';
import { MinorUpdateChangeComponent } from '../dialogs/minor-update-change/minor-update-change.component';

/**
 * Service gérant toutes les ouvertures de dialog dans easi-forum
 */
@Injectable({
    providedIn: 'root'
})
export class DialogService {
    constructor(
        private dialog: MatDialog,
        private libraryService: LibraryService,
        private loginService: LoginService,
        private userService: UserService
    ) {
        this.libraryService.emitWarningDialog.subscribe((data: any) => {
            this.openWarning(data.body, data.title);
        });
        this.libraryService.emitContentDetailsDialog.subscribe((contentId: number) => {
            this.openContentDetails(contentId);
        });
        this.subscriptions.add(
            this.loginService.closeAllDialogs$.subscribe((data: boolean) => {
                if (data) {
                    this.dialog.closeAll();
                }
            })
        );
    }

    private subscriptions: Subscription = new Subscription();

    openAddDisponibility(assignment: any) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = assignment;

        const ref: MatDialogRef<AddDisponibilityComponent> = this.dialog.open(
            AddDisponibilityComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openAddValidator(content: Content) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = content;

        const ref: MatDialogRef<AddValidatorComponent> = this.dialog.open(
            AddValidatorComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openAdvancedAssignationDialog(content: Content | Array<Content>) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '1000px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = content;

        const ref: MatDialogRef<AdvancedAssignationComponent> = this.dialog.open(
            AdvancedAssignationComponent,
            dialogConfig
        );
        ref.componentInstance.emitOpenSelectFIFCDialog.subscribe(() => {
            this.openSelectFIFCDialog().subscribe((fi: boolean) => {
                ref.componentInstance.getOpenSelectFIFCDialog.next(fi);
            });
        });
        ref.componentInstance.emitOpenDevoirConfirmationDialog.subscribe(() => {
            this.openDevoirConfirmation().subscribe((data: boolean) => {
                ref.componentInstance.getOpenDevoirConfirmationDialog.next(data);
            });
        });
        return ref.afterClosed();
    }

    openApproveContent(content: Content) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '450px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = content;

        const ref: MatDialogRef<ApproveContentComponent> = this.dialog.open(
            ApproveContentComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openAssemblyConfirmation(content: Content, mode: string) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        dialogConfig.data.content = content;
        dialogConfig.data.mode = mode;

        const ref: MatDialogRef<AssemblyConfirmationComponent> = this.dialog.open(
            AssemblyConfirmationComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    /**
     * popup de confirmation
     * @param title
     * @param message
     * @param buttonsValues objet optionnel permettant de changer le texte des boutons
     */
    openConfirmationDialog(
        title: string,
        message: string,
        buttonsValues?: { yes: string; no: string }
    ) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {
            title: title,
            message: message,
            buttonsValues: buttonsValues
        };
        const ref: MatDialogRef<ConfirmationComponent> = this.dialog.open(
            ConfirmationComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openContentCreation(content: Content) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.data = content;

        const ref: MatDialogRef<ContentCreationComponent> = this.dialog.open(
            ContentCreationComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openError(msg);
        });
        return ref.afterClosed();
    }

    openContentDetails(contentId: number) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = contentId;

        const ref: MatDialogRef<ContentDetailsComponent> = this.dialog.open(
            ContentDetailsComponent,
            dialogConfig
        );
        ref.componentInstance.openContentCreation.subscribe((content: Content) => {
            this.openContentCreation(content);
        });
        ref.componentInstance.openEditAuthor.subscribe((content: Content) => {
            this.openEditAuthor(content).subscribe((newAuthor) => {
                ref.componentInstance.author = newAuthor;
            });
        });
        return ref.afterClosed();
    }

    openEditAuthor(content: Content) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = content;

        const ref: MatDialogRef<EditAuthorComponent> = this.dialog.open(
            EditAuthorComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openContentHistory(content: Content) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = content;

        const ref: MatDialogRef<ContentHistoryComponent> = this.dialog.open(
            ContentHistoryComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openContentSelection() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '650px';
        dialogConfig.maxHeight = '95vh';

        const ref: MatDialogRef<ContentSelectionComponent> = this.dialog.open(
            ContentSelectionComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openCopyMediaDialog(content: Content, isCopy: boolean, isMedia: boolean) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {
            content: content,
            isCopy: isCopy,
            isMedia: isMedia
        };

        const ref: MatDialogRef<CopyMediaComponent> = this.dialog.open(
            CopyMediaComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openCreateDomain(domain?: Domain) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        if (domain) {
            dialogConfig.data = domain;
        }

        const ref: MatDialogRef<CreateDomainComponent> = this.dialog.open(
            CreateDomainComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openCreateProvider() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';

        const ref: MatDialogRef<CreateProviderComponent> = this.dialog.open(
            CreateProviderComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openReportActivityError(content: Content) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = content;

        const ref: MatDialogRef<ReportActivityErrorComponent> = this.dialog.open(
            ReportActivityErrorComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openDevoirConfirmation() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';

        const ref: MatDialogRef<DevoirConfirmationComponent> = this.dialog.open(
            DevoirConfirmationComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openUserTracking(assignment: Assignment, target: any) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '700px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        dialogConfig.data.assignment = assignment;
        dialogConfig.data.target = target;

        const ref: MatDialogRef<UserTrackingComponent> = this.dialog.open(
            UserTrackingComponent,
            dialogConfig
        );
        ref.componentInstance.openUploadDevoir$.subscribe({
            next: (data: {
                assignment: Assignment;
                user: User | undefined;
                group: Group | undefined;
            }) => {
                this.openUploadDevoirCorrectionDialog(data).subscribe((uploadResponse: any) => {
                    if (uploadResponse.correction_file) {
                        if (
                            data.user &&
                            ref.componentInstance.tracking.some(
                                (user) => user.userid === data.user.userid
                            )
                        ) {
                            ref.componentInstance.tracking.find(
                                (user) => user.userid === data.user.userid
                            ).correction_file = uploadResponse.correction_file;
                        } else if (data.group) {
                            ref.componentInstance.assignment.correction_file =
                                uploadResponse.correction_file;
                        }
                    }
                });
            }
        });
        ref.componentInstance.openDevoirNewAttemptConfirmation$.subscribe({
            next: (user: User) => {
                this.openConfirmationDialog(
                    'Proposer un nouveau dépot',
                    "Vous allez proposer une nouvelle tentative :<br><br>Le statut de l'activité sera réinitialisé<br>Le devoir rendu précédemment ainsi que la note seront supprimés définitivement<br><br><b>Continuer</b> ?"
                ).subscribe((confirmation: boolean) => {
                    if (confirmation) {
                        ref.componentInstance.onConfirmNewAttempt(user);
                    }
                });
            }
        });

        ref.componentInstance.openAnonymousConfirmationDialog$.subscribe({
            next: ({ allDevoirsReady }) => {
                this.openRevealAnonymousDevoirConfirmationDialog(allDevoirsReady).subscribe(
                    (choice: boolean | { missingNotes: 0 | null }) => {
                        ref.componentInstance.teacherChoiceForAnonymousAssignation$.next(choice);
                    }
                );
            }
        });
        return ref.afterClosed();
    }

    /**
     * @param content Le message d'erreur que l'on souhaite afficher
     * Ouvre la fenetre d'erreur
     */
    openUploadDevoirCorrectionDialog(data: {
        assignment: Assignment;
        user: User;
    }): Observable<any> {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '700px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = data;

        const ref: MatDialogRef<UploadDevoirCorrectionComponent> = this.dialog.open(
            UploadDevoirCorrectionComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    /**
     * popup de confirmation
     * @param title
     * @param message
     * @param buttonsValues objet optionnel permettant de changer le texte des boutons
     */
    openRevealAnonymousDevoirConfirmationDialog(allDevoirsReady: boolean) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {
            allDevoirsReady
        };
        const ref: MatDialogRef<RevealAnonymousDevoirConfirmationComponent> = this.dialog.open(
            RevealAnonymousDevoirConfirmationComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openError(content?: String) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        if (content) {
            dialogConfig.data = content;
        }

        const ref: MatDialogRef<ErrorComponent> = this.dialog.open(ErrorComponent, dialogConfig);
        return ref.afterClosed();
    }

    openExchange(data) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = data;

        const ref: MatDialogRef<ExchangeComponent> = this.dialog.open(
            ExchangeComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openExportCatalog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';

        const ref: MatDialogRef<ExportCatalogComponent> = this.dialog.open(
            ExportCatalogComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openGroupTracking(assignment: Assignment, target: any) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '700px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        dialogConfig.data.assignment = assignment;
        dialogConfig.data.target = target;

        const ref: MatDialogRef<GroupTrackingComponent> = this.dialog.open(
            GroupTrackingComponent,
            dialogConfig
        );
        ref.componentInstance.openUploadDevoir.subscribe({
            next: (data: any) => {
                this.openUploadDevoirCorrectionDialog(data).subscribe((uploadResponse: any) => {
                    if (uploadResponse.correction_file) {
                        if (
                            data.user &&
                            ref.componentInstance.tracking.some(
                                (user) => user.userid === data.user.userid
                            )
                        ) {
                            ref.componentInstance.tracking.find(
                                (user) => user.userid === data.user.userid
                            ).correction_file = uploadResponse.correction_file;
                        } else if (data.group) {
                            ref.componentInstance.assignment.correction_file =
                                uploadResponse.correction_file;
                        }
                    }
                });
            }
        });
        ref.componentInstance.openAnonymousConfirmationDialog.subscribe({
            next: ({ allDevoirsReady }) => {
                this.openRevealAnonymousDevoirConfirmationDialog(allDevoirsReady).subscribe(
                    (data) => {
                        ref.componentInstance.initGradeGroup(data);
                    }
                );
            }
        });
        ref.componentInstance.openConfirmationDialog.subscribe({
            next: (data) => {
                const dialog: any = {};
                switch (data.key) {
                    case 'validate_assignment': {
                        dialog.title = 'Forcer la validation';
                        dialog.body =
                            "Par défaut le calcul du statut des assemblages est automatique.<br />Si vous décidez de forcer la validation ou l'invalidation, l'automatisme sera définitivement désactivé pour cet assemblage.<br />Êtes-vous sûr de vouloir forcer le changement de statut de cet élément ?";
                        break;
                    }
                    case 'reset_tracking':
                    case 'reset_group_tracking': {
                        if (data.assignment.type === 'devoir') {
                            dialog.title = 'Proposer un nouveau dépot';
                        } else {
                            dialog.title = 'Proposer une nouvelle tentative';
                        }
                        if (data.assignment.type === 'quiz') {
                            dialog.body =
                                "Vous allez proposer une nouvelle tentative :<br><br>Le statut de l'activité et la copie de l'apprenant seront réinitialisés.<br>Les résultats des anciennes tentatives seront conservés et visibles dans le rapport de test.<br><br><b>Continuer</b> ?";
                        } else if (data.assignment.type === 'scorm') {
                            dialog.body =
                                "Vous allez proposer une nouvelle tentative :<br><br>Le statut de l'activité sera réinitialisé.<br>Le score de la tentative précédente ne sera pas conservée.<br>Seul le nombre de tentatives sera mis à jour dans le rapport de l'apprenant (.csv)<br><br><b>Continuer</b> ?";
                        } else if (data.assignment.type === 'devoir') {
                            dialog.body =
                                "Vous allez proposer une nouvelle tentative :<br><br>Le statut de l'activité sera réinitialisé<br>Le devoir rendu précédemment ainsi que la note seront supprimés définitivement<br><br><b>Continuer</b> ?";
                        } else {
                            dialog.body =
                                "Vous allez proposer une nouvelle tentative :<br><br>Le statut de l'activité sera réinitialisé.<br><br><b>Continuer</b> ?";
                        }
                        break;
                    }
                    default: {
                        dialog.title = "Texte en attente de l'UIMM";
                        dialog.body = "Texte en attente de l'UIMM";
                    }
                }
                this.openConfirmationDialog(dialog.title, dialog.body).subscribe(
                    (valid: boolean) => {
                        if (valid) {
                            switch (data.key) {
                                case 'validate_assignment': {
                                    ref.componentInstance.onConfirmValidateAssignment(data.user);
                                    break;
                                }
                                case 'reset_tracking': {
                                    ref.componentInstance.onConfirmResetTracking(data.user);
                                    break;
                                }
                                case 'reset_group_tracking': {
                                    ref.componentInstance.onConfirmResetGroupTracking();
                                    break;
                                }
                            }
                        }
                    }
                );
            }
        });
        return ref.afterClosed();
    }

    openLibraryConfirmationDialog(
        title: string,
        message: string,
        submessage?: string,
        withComment = true,
        comment?: string
    ) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {
            title: title,
            message: message,
            submessage: submessage,
            withComment: withComment,
            comment: comment
        };
        const ref: MatDialogRef<LibraryConfirmationComponent> = this.dialog.open(
            LibraryConfirmationComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openMassImport(importType: string) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '1000px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        dialogConfig.data.importType = importType;

        const ref: MatDialogRef<MassImportComponent> = this.dialog.open(
            MassImportComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openError(msg);
        });
        return ref.afterClosed();
    }

    openMassUpdate() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '1000px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};

        const ref: MatDialogRef<MassUpdateComponent> = this.dialog.open(
            MassUpdateComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openError(msg);
        });
        return ref.afterClosed();
    }

    openMinorUpdateChange(assignment: Assignment) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = assignment;

        const ref: MatDialogRef<MinorUpdateChangeComponent> = this.dialog.open(
            MinorUpdateChangeComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openPersonnalizeGroupAssignment(assignment: Assignment) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = assignment;

        const ref: MatDialogRef<PersonnalizeGroupAssignmentComponent> = this.dialog.open(
            PersonnalizeGroupAssignmentComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openRedirectMedia() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';

        const ref: MatDialogRef<RedirectMediaComponent> = this.dialog.open(
            RedirectMediaComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openRejectContent(content: Content) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '450px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = content;

        const ref: MatDialogRef<RejectContentComponent> = this.dialog.open(
            RejectContentComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openDelegateValidation(content: Content) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = content;

        const ref: MatDialogRef<DelegateValidationComponent> = this.dialog.open(
            DelegateValidationComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openSelectGroupReportingDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';

        const ref: MatDialogRef<SelectGroupReporting> = this.dialog.open(
            SelectGroupReporting,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openSelectFIFCDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';

        const ref: MatDialogRef<SelectFIFCComponent> = this.dialog.open(
            SelectFIFCComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openSelectPublication(content: Content) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = content;

        const ref: MatDialogRef<SelectPublicationComponent> = this.dialog.open(
            SelectPublicationComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openSelectValidator(content: Content) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = content;

        const ref: MatDialogRef<SelectValidatorComponent> = this.dialog.open(
            SelectValidatorComponent,
            dialogConfig
        );
        ref.componentInstance.openContentHistory.subscribe((data: Content) => {
            this.openContentHistory(data);
        });
        return ref.afterClosed();
    }

    openUserHistory(user: User) {
        const iframe: HTMLIFrameElement = document.getElementById(
            'header-container'
        ) as HTMLIFrameElement;
        iframe.contentWindow.postMessage({ openUserHistory: user, user: user }, '*');
    }

    openViewActivityError(content: Content, nationalAdminMode: boolean) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = content;
        dialogConfig.data.nationalAdminMode = nationalAdminMode;

        const ref: MatDialogRef<ViewActivityErrorComponent> = this.dialog.open(
            ViewActivityErrorComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openWarning(body: string, title?: string) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        if (title) {
            dialogConfig.data.title = title;
        }
        dialogConfig.data.body = body;

        const ref: MatDialogRef<WarningComponent> = this.dialog.open(
            WarningComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openInfo(body: string, title?: string) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        if (title) {
            dialogConfig.data.title = title;
        }
        dialogConfig.data.body = body;

        const ref: MatDialogRef<InfoComponent> = this.dialog.open(InfoComponent, dialogConfig);
        return ref.afterClosed();
    }

    openActivityRating(content: Content): Observable<any> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '400px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {
            activity: content
        };

        const ref: MatDialogRef<ActivityRatingComponent> = this.dialog.open(
            ActivityRatingComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }
}
