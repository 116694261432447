import {
    Component,
    OnInit,
    Input,
    Injectable,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    AfterViewInit,
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import { SafeHtml, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { LibraryService } from '../../../../services/library.service';
import { FavoriteService } from '../../../../services/favorite.service';
import { LoginService } from '../../../../services/login.service';
import { QuizService } from '../../../../services/quiz.service';
import { DialogService } from '../../../../services/dialog.service';
import { MultiselectService } from '../../../../services/library-multiselect.service';
import { FlashMessageService } from '../../../../services/flash-message.service';
import { ConfigService } from '../../../../services/config.service';
import { ArchiveService } from '../../../../services/archive.service';
import { AssignmentService } from '../../../../services/assignment.service';
import { UserService } from '../../../../services/user.service';

import { Content, ReportStatus } from '../../../../structures/content';
import { User } from '../../../../structures/user';

import { DatesPipe } from '../../../../pipes/dates.pipe';

import { MoreActionsDirective } from './more-actions/more-actions.directive';

import * as FileSaver from 'file-saver';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-library-entry',
    templateUrl: './library-entry.component.html',
    styleUrls: ['./library-entry.component.scss'],
    providers: [DatesPipe, MoreActionsDirective],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@Injectable({
    providedIn: 'root'
})
export class LibraryEntryComponent implements OnInit, AfterViewChecked, AfterViewInit {
    @Input() entryContent: Content;
    @Input() isArchiveView: boolean;
    @Input() isReportView: boolean;
    @Input() isDisabledView: boolean;
    @Input() isPreviousVersionView: boolean;
    @Input() isCatalogView: boolean;

    @Input() parentOfMode: boolean;
    @Input() parentOfId: number = undefined;
    @Output() parentOf: EventEmitter<Content> = new EventEmitter();
    @Output() searchInLibrary: EventEmitter<number> = new EventEmitter();

    @ViewChild('hoverIcons', { static: false }) hoverIconsContainer: ElementRef;
    @ViewChild('libraryEntryIcons', { static: false }) libraryEntryIconsContainer: ElementRef;
    @ViewChild('visibleIcons', { static: false }) visibleIconsContainer: ElementRef;
    @ViewChild('libraryEntryHeaderContent', { static: false })
    libraryEntryHeaderContentContainer: ElementRef;

    opened: boolean;
    note: any;
    showIconPlus = false;
    numberOfHoverIconsCanFit = 0;
    fullsEntryWidth: number;
    visibleIconsWidth: number;
    className = 'library-entry-' + Math.floor(Math.random() * 999999);

    subscriptions = new Subscription();

    constructor(
        private libraryService: LibraryService,
        private loginService: LoginService,
        private dialogService: DialogService,
        private quizService: QuizService,
        private multiselectService: MultiselectService,
        private archiveService: ArchiveService,
        private favoriteService: FavoriteService,
        private flashMessageService: FlashMessageService,
        private configService: ConfigService,
        private assignmentService: AssignmentService,
        private userService: UserService,
        private datesPipe: DatesPipe,
        private detector: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.note = Math.floor(Math.random() * 50) / 10;
        this.opened = false;

        this.subscriptions.add(
            this.libraryService.contentDisabled.subscribe((content: Content) => {
                if (this.entryContent.id === content.id) {
                    this.entryContent.enabled = false;
                }
            })
        );
        this.subscriptions.add(
            this.libraryService.contentEnabled.subscribe((content: Content) => {
                if (this.entryContent.id === content.id) {
                    this.entryContent.enabled = true;
                }
            })
        );
        this.subscriptions.add(
            this.libraryService.contentArchived.subscribe((content: Content) => {
                for (const i in this.entryContent.children) {
                    if (this.entryContent.children[i].id === content.id) {
                        this.entryContent.children.splice(+i, 1);
                    }
                }
            })
        );
        this.subscriptions.add(
            this.libraryService.contentUnarchived.subscribe((content: Content) => {
                for (const i in this.entryContent.children) {
                    if (this.entryContent.children[i].id === content.id) {
                        this.entryContent.children.splice(+i, 1);
                    }
                }
            })
        );
        this.subscriptions.add(
            this.libraryService.contentDeleted.subscribe((content: Content) => {
                for (const i in this.entryContent.children) {
                    if (this.entryContent.children[i].id === content.id) {
                        this.entryContent.children.splice(+i, 1);
                    }
                }
            })
        );
        this.subscriptions.add(
            this.libraryService.updatedDraft.subscribe((content: Content) => {
                if (this.entryContent.id === content.id) {
                    this.entryContent = {
                        ...this.entryContent,
                        ...content
                    };
                }
            })
        );
        this.subscriptions.add(
            this.libraryService.detectChanges.subscribe(() => {
                this.detector.detectChanges();
            })
        );
    }

    ngAfterViewInit() {
        if (!this.isCatalogView) {
            this.gridItemsToShow();
        }
    }

    ngAfterViewChecked() {
        // gridItemsToShow recalculé suelement si la taille a changé
        if (!this.isCatalogView) {
            if (
                (this.libraryEntryHeaderContentContainer.nativeElement as HTMLElement)
                    .offsetWidth !== this.fullsEntryWidth ||
                (this.visibleIconsContainer.nativeElement as HTMLElement).offsetWidth !==
                    this.visibleIconsWidth
            ) {
                this.gridItemsToShow();
            }
        }
    }

    /**
     * Calcule le nombre d'élements à afficher dans la grille
     */
    gridItemsToShow() {
        const visibleIconsWidth = (this.visibleIconsContainer.nativeElement as HTMLElement)
            .offsetWidth;
        const fullEntryWidth = (this.libraryEntryHeaderContentContainer
            .nativeElement as HTMLElement).offsetWidth;
        const widthAvailable = fullEntryWidth - visibleIconsWidth - 205;
        const numberOfIconsPossible = Math.floor(widthAvailable / 30);
        this.numberOfHoverIconsCanFit = numberOfIconsPossible;
        this.fullsEntryWidth = fullEntryWidth;
        this.visibleIconsWidth = visibleIconsWidth;
    }

    showReportNewIcon(): boolean {
        if (this.entryContent.report.unseen_new_status) {
            if (this.loginService.isNationalAdmin()) {
                return this.entryContent.report.status === ReportStatus.created;
            } else {
                return (
                    this.entryContent.report.author.id === this.loginService.getUser().id &&
                    this.entryContent.report.status !== ReportStatus.created
                );
            }
        } else {
            return false;
        }
    }

    getReportNewIconTooltip(): string {
        switch (this.entryContent.report.status) {
            case ReportStatus.created:
                return 'Nouveau';
            case ReportStatus.inprogress:
                return 'En cours de traitement';
            case ReportStatus.canceled:
                return 'Ne sera pas corrigé';
            case ReportStatus.closed:
                return 'Corrigé';
        }
    }

    /**
     * retourne le nom de l'icône à afficher pour le contenu
     */
    getIcon(): string {
        if (this.multiselectService.isContentSelected(this.entryContent)) {
            return 'icon-Valide selected';
        } else {
            return this.libraryService.getIcon(this.entryContent);
        }
    }

    getCatalogTitle() {
        if (this.entryContent.forced_title) {
            return this.entryContent.forced_title;
        }
        return this.entryContent.title;
    }

    getCatalogDuration() {
        if (this.entryContent.forced_duration) {
            return this.entryContent.forced_duration;
        }
        return this.entryContent.duration;
    }

    getCatalogInformation() {
        if (this.entryContent.information) {
            return this.entryContent.information;
        }
    }

    getStripeFontSize() {
        if (!this.entryContent.forced_stripe) {
            return '14px';
        } else {
            if (this.entryContent.forced_stripe_text.length < 10) {
                return '14px';
            } else if (this.entryContent.forced_stripe_text.length < 15) {
                return '12px';
            } else if (this.entryContent.forced_stripe_text.length < 20) {
                return '10px';
            } else {
                return '8px';
            }
        }
    }

    viewInLibrary() {
        this.libraryService.emitViewInLibrary(this.entryContent.id);
    }

    getTitleTooltip() {
        return `<strong>Titre : </strong>${this.entryContent.title}</br>
        ${this.entryContent.id ? '<strong>ID : </strong>' : ''}${this.entryContent.id || ''}${
            this.entryContent.ucode ? '<strong> Code : </strong>' : ''
        }
        ${this.entryContent.ucode || ''}</br>
        ${this.entryContent.description ? '<strong>Description : </strong>' : ''}
        <div class="description-in-tooltip" style="color: red;">${
            this.entryContent.description.replace(/\n/g, '<br />') || ''
        }</div>
        </br>
        ${this.entryContent.timecreated ? '<strong>Créé le : </strong>' : ''}${
            this.entryContent.timecreated
                ? this.datesPipe.transform(this.entryContent.timecreated)
                : ''
        }
        ${
            this.entryContent.timemodified &&
            this.entryContent.timemodified.toString() !== '1970/01/01T01:00:00'
                ? '<strong>Modifié le : </strong>'
                : ''
        }
        ${
            this.entryContent.timemodified &&
            this.entryContent.timemodified.toString() !== '1970/01/01T01:00:00'
                ? this.datesPipe.transform(this.entryContent.timemodified)
                : ''
        }
        ${this.getFormatedDuration() ? '<br />' + this.getFormatedDuration() : ''}
        ${this.isNationalContent() ? '<br/>Contenu national' : ''}
        ${
            this.entryContent.comment
                ? '<br/><strong>Commentaire : </strong>' +
                  this.entryContent.comment +
                  ' (' +
                  new Date(this.entryContent.comment_date).toLocaleDateString() +
                  ')'
                : ''
        }`;
    }

    /**
     * renvoie la durée formatée si elle existe.
     * si la durée en h est 0 => renvoie XXmin
     * sinon renvoie XXh:YYmin
     */
    getFormatedDuration() {
        if (
            this.entryContent.level === 'bloc' ||
            this.entryContent.level === 'parcours' ||
            this.entryContent.level === 'competence'
        ) {
            return '';
        } else if (!this.entryContent.duration) {
            return '';
        } else {
            let warning = '';
            if (this.isIncompleteDuration()) {
                warning = "<br />durée(s) d'activité(s) manquante(s) dans l'assemblage";
            }
            const preString = `<strong>Durée estimée : </strong>`;
            const splittedTime = this.entryContent.duration.split(':');

            if (splittedTime[0] === '00' && splittedTime[1] === '00') {
                return '';
            } else {
                if (splittedTime[0] === '00') {
                    return preString + splittedTime[1] + 'mn' + warning;
                }
                if (splittedTime[0][0] === '0') {
                    splittedTime[0] = splittedTime[0][1];
                }
                return preString + splittedTime[0] + 'h' + splittedTime[1] + 'mn' + warning;
            }
        }
    }

    showDuration() {
        return (
            !!this.entryContent.duration &&
            (this.entryContent.level === 'module' ||
                this.entryContent.level === 'sequence' ||
                !this.isAssembly()) &&
            this.entryContent.duration !== '00:00' &&
            this.getFormatedDuration() !== ''
        );
    }

    isIncompleteDuration() {
        if (this.isAssembly()) {
            return this.entryContent.incomplete_duration;
        }
        return false;
    }

    enableContent($event: Event) {
        $event.stopImmediatePropagation();
        const message = `Êtes-vous sûr de vouloir activer le contenu "${this.entryContent.title} ?`;
        const title = 'Activer un contenu';
        const submessage =
            "Activer les enfants de façon récursive.<br>Attention, certains enfants peuvent être présents dans d'autres assemblages.";
        this.subscriptions.add(
            this.dialogService
                .openLibraryConfirmationDialog(title, message, submessage)
                .subscribe((data: any) => {
                    if (data) {
                        if (data.recursive) {
                            this.subscriptions.add(
                                this.libraryService
                                    .recursiveAction(this.entryContent, 'enable')
                                    .subscribe(() => {
                                        this.flashMessageService.flash(
                                            `Le contenu ${this.entryContent.title} a été activé`
                                        );
                                        this.libraryService.emitRefreshLibrary();
                                        this.detector.detectChanges();
                                    })
                            );
                        } else {
                            this.subscriptions.add(
                                this.libraryService
                                    .enableContent(this.entryContent)
                                    .subscribe(() => {
                                        this.flashMessageService.flash(
                                            `Le contenu ${this.entryContent.title} a été activé`
                                        );
                                        this.entryContent.enabled = true;
                                        this.detector.detectChanges();
                                    })
                            );
                        }
                    }
                })
        );
    }

    disableContent($event: Event) {
        $event.stopImmediatePropagation();
        this.markReportAsRead();
        const message = `Si vous désactivez votre contenu il ne sera plus visible dans la bibliothèque et ne sera plus assignable.<br />Les assignations courantes seront toujours disponibles.<br /><br />Êtes-vous sûr de vouloir désactiver le contenu ${this.entryContent.title} ?`;
        const title = `Désactiver le contenu [#${this.entryContent.id}]`;
        const submessage =
            "Désactiver les enfants de façon récursive.<br>Attention, certains enfants peuvent être présents dans d'autres assemblages.";
        this.subscriptions.add(
            this.dialogService
                .openLibraryConfirmationDialog(title, message, submessage, true)
                .subscribe((data: any) => {
                    if (data) {
                        if (data.recursive) {
                            this.subscriptions.add(
                                this.libraryService
                                    .recursiveAction(this.entryContent, 'disable', data.comment)
                                    .subscribe(() => {
                                        this.flashMessageService.flash(
                                            `Le contenu ${this.entryContent.title} a été désactivé`
                                        );
                                        this.libraryService.emitRefreshLibrary();
                                        this.detector.detectChanges();
                                    })
                            );
                        } else {
                            this.subscriptions.add(
                                this.libraryService
                                    .disableContent(this.entryContent, data.comment)
                                    .subscribe(() => {
                                        this.flashMessageService.flash(
                                            `Le contenu ${this.entryContent.title} a été désactivé`
                                        );
                                        this.libraryService.emitRefreshLibrary();
                                        this.detector.detectChanges();
                                    })
                            );
                        }
                    }
                })
        );
    }

    archiveContent($event): void {
        $event.stopImmediatePropagation();
        this.markReportAsRead();
        const message = `Etes-vous sûr de vouloir archiver le contenu ${this.entryContent.title} ?`;
        const title = `Archiver le contenu [#${this.entryContent.id}]`;
        const submessage =
            "Archiver les enfants de façon récursive.<br>Attention, certains enfants peuvent être présents dans d'autres assemblages.";
        this.subscriptions.add(
            this.dialogService
                .openLibraryConfirmationDialog(
                    title,
                    message,
                    submessage,
                    true,
                    this.entryContent.comment
                )
                .subscribe((data: any) => {
                    if (data) {
                        if (data.recursive) {
                            this.subscriptions.add(
                                this.libraryService
                                    .recursiveAction(this.entryContent, 'archive', data.comment)
                                    .subscribe(() => {
                                        this.flashMessageService.flash(
                                            `Le contenu ${this.entryContent.title} a été archivé`
                                        );
                                        this.libraryService.emitRefreshLibrary();
                                        this.detector.detectChanges();
                                    })
                            );
                        } else {
                            this.subscriptions.add(
                                this.archiveService
                                    .archiveContent(this.entryContent, data.comment)
                                    .subscribe(() => {
                                        this.flashMessageService.flash(
                                            `Le contenu ${this.entryContent.title} a été archivé`
                                        );
                                        this.libraryService.emitRefreshLibrary();
                                        this.detector.detectChanges();
                                    })
                            );
                        }
                    }
                })
        );
    }

    unarchiveContent($event): void {
        $event.stopImmediatePropagation();
        const message = `Etes-vous sûr de vouloir désarchiver le contenu ${this.entryContent.title} ?`;
        const title = 'Désarchiver un contenu';
        const submessage =
            "Désarchiver les enfants de façon récursive.<br>Attention, certains enfants peuvent être présents dans d'autres assemblages.";
        this.subscriptions.add(
            this.dialogService
                .openLibraryConfirmationDialog(title, message, submessage)
                .subscribe((data: any) => {
                    if (data) {
                        if (data.recursive) {
                            this.subscriptions.add(
                                this.libraryService
                                    .recursiveAction(this.entryContent, 'unarchive')
                                    .subscribe(() => {
                                        this.flashMessageService.flash(
                                            `Le contenu ${this.entryContent.title} a été désarchivé`
                                        );
                                        this.libraryService.emitRefreshLibrary();
                                        this.detector.detectChanges();
                                    })
                            );
                        } else {
                            this.subscriptions.add(
                                this.archiveService
                                    .unarchiveContent(this.entryContent)
                                    .subscribe(() => {
                                        this.flashMessageService.flash(
                                            `Le contenu ${this.entryContent.title} a été désarchivé`
                                        );
                                        this.libraryService.emitRefreshLibrary();
                                        this.detector.detectChanges();
                                    })
                            );
                        }
                    }
                })
        );
    }

    deleteContent($event): void {
        $event.stopImmediatePropagation();
        const message = `Etes-vous sûr de vouloir supprimer le contenu ${this.entryContent.title} ?`;
        const title = 'Supprimer un contenu';
        const submessage =
            "Supprimer les enfants de façon récursive.<br>Attention, certains enfants peuvent être présents dans d'autres assemblages.";
        this.subscriptions.add(
            this.dialogService
                .openLibraryConfirmationDialog(title, message, submessage)
                .subscribe((data: any) => {
                    if (data) {
                        if (data.recursive) {
                            this.subscriptions.add(
                                this.libraryService
                                    .recursiveAction(this.entryContent, 'delete')
                                    .subscribe(() => {
                                        this.flashMessageService.flash(
                                            `Le contenu ${this.entryContent.title} a été supprimé`
                                        );
                                        this.libraryService.emitRefreshLibrary();
                                        this.detector.detectChanges();
                                    })
                            );
                        } else {
                            this.subscriptions.add(
                                this.libraryService
                                    .deleteContent(this.entryContent)
                                    .subscribe(() => {
                                        this.flashMessageService.flash(
                                            `Le contenu ${this.entryContent.title} a été supprimé`
                                        );
                                        this.libraryService.emitRefreshLibrary();
                                        this.detector.detectChanges();
                                    })
                            );
                        }
                    }
                })
        );
    }

    reportActivityError($event): void {
        $event.stopImmediatePropagation();
        this.dialogService
            .openReportActivityError(this.entryContent)
            .subscribe(({ activityReported }) => {
                if (activityReported === true) {
                    const body = `<br />Vous pourrez suivre vos demandes dans « Afficher les contenus signalés »<br />
                (Drapeau <span class="icon-flag"></span> en haut à droite de votre vue bibliothèque)`;
                    const title = 'Votre signalement est enregistré.';
                    this.dialogService.openInfo(body, title);
                }
            });
    }

    exportQuiz($event): void {
        $event.stopImmediatePropagation();
        this.markReportAsRead();
        this.subscriptions.add(
            this.libraryService.getContent(this.entryContent.id).subscribe((success: any) => {
                const quizId = success.path.split('/')[success.path.split('/').length - 1];
                this.subscriptions.add(
                    this.quizService.exportQuiz(quizId).subscribe((dataJson: any) => {
                        const blob = new Blob([JSON.stringify(dataJson)], {
                            type: 'application/json'
                        });
                        FileSaver.saveAs(blob, success.title + '.json');
                    })
                );
            })
        );
    }

    openReporting($event): void {
        $event.stopImmediatePropagation();
        this.markReportAsRead();
        window.open(
            `${this.configService.getReportOldFrontEndpoint()}#/?content=${
                this.entryContent.id
            }&searchTerm=${this.entryContent.title}`,
            '_blank'
        );
    }

    viewContent($event): void {
        $event.stopImmediatePropagation();
        this.markReportAsRead();
        this.dialogService.openContentDetails(this.entryContent.id);
    }

    canViewGuide(): boolean {
        return !!this.entryContent.guide;
    }

    viewGuide($event): void {
        $event.stopImmediatePropagation();
        const warningTitle = "Impossible d'ouvrir la page";
        const warningBody =
            "Vous utilisez un bloqueur de pop-ups qui vous empêche d'ouvrir un nouvel onglet. <br >Veuillez modifier les paramètres de votre navigateur pour autoriser les pop-ups pour le site : <br><strong>easi-training.fr</strong>";
        const popup = window.open(this.entryContent.guide, this.entryContent.title);
        if (!popup) {
            this.dialogService.openWarning(warningBody, warningTitle);
        }
    }

    /**
     * Opens the dialog that leads to copy the content
     * @param entry the content to be cloned
     */
    copyContent($event: Event): void {
        $event.stopImmediatePropagation();
        this.markReportAsRead();
        this.subscriptions.add(
            this.dialogService
                .openCopyMediaDialog(
                    this.entryContent,
                    true,
                    this.entryContent.type === 'media' || this.entryContent.type === 'quiz'
                )
                .subscribe((result: any) => {
                    if (result) {
                        this.subscriptions.add(
                            this.libraryService
                                .duplicateContent(this.entryContent, true)
                                .subscribe((success: any) => {
                                    this.libraryService.emitAddedDraft(success);
                                    this.flashMessageService.flash(
                                        `Le contenu ${this.entryContent.title} a été copié dans l'espace de création`
                                    );
                                    if (result === 'media') {
                                        window.open(
                                            this.configService.getEasiMediaFrontEndPoint(),
                                            '_blank'
                                        );
                                    }
                                })
                        );
                    }
                })
        );
    }

    /**
     * Opens the dialogs leading to edit the content
     * @param entry the content to be modified
     */
    editContent($event: Event): void {
        $event.stopImmediatePropagation();
        this.markReportAsRead();
        this.subscriptions.add(
            this.dialogService
                .openCopyMediaDialog(
                    this.entryContent,
                    false,
                    this.entryContent.type === 'media' || this.entryContent.type === 'quiz'
                )
                .subscribe((result: any) => {
                    if (result) {
                        this.subscriptions.add(
                            this.libraryService
                                .duplicateContent(this.entryContent, false)
                                .subscribe((success: any) => {
                                    this.libraryService.emitAddedDraft(success);
                                    this.flashMessageService.flash(
                                        `Le contenu ${this.entryContent.title} a été copié dans l'espace de création`
                                    );
                                    if (result === 'media') {
                                        window.open(
                                            this.configService.getEasiMediaFrontEndPoint(),
                                            '_blank'
                                        );
                                    }
                                })
                        );
                    }
                })
        );
    }

    nationalizeContent($event): void {
        $event.stopImmediatePropagation();
        this.dialogService.openSelectPublication(this.entryContent).subscribe((data: any) => {
            if (data) {
                this.subscriptions.add(
                    this.libraryService
                        .nationalizeContent(this.entryContent.id, data.publicationMode)
                        .subscribe(() => {
                            this.flashMessageService.flash(
                                `Le contenu ${this.entryContent.title} a été nationalisé`
                            );
                            this.libraryService.emitRefreshLibrary();
                            this.detector.detectChanges();
                        })
                );
            }
        });
    }

    openAdvancedAssignation($event): void {
        $event.stopImmediatePropagation();
        this.dialogService.openAdvancedAssignationDialog(this.entryContent);
    }

    /**
     * toggles favorite status of the content
     */
    addToFavorites($event): void {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.favoriteService.addContentToFavorites(this.entryContent).subscribe((data: any) => {
                this.entryContent.favorite = true;
                this.flashMessageService.flash(
                    `Le contenu ${this.entryContent.title} a été ajouté à vos favoris`
                );
                this.favoriteService.emitContentAddedToFavorites(this.entryContent);
                this.detector.detectChanges();
            })
        );
    }

    /**
     * toggles favorite status of the content
     */
    removeFromFavorites($event): void {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.favoriteService
                .removeContentFromFavorites(this.entryContent)
                .subscribe((data: any) => {
                    this.entryContent.favorite = false;
                    this.flashMessageService.flash(
                        `Le contenu ${this.entryContent.title} a été retiré de vos favoris`
                    );
                    this.favoriteService.emitContentRemovedFromFavorites(this.entryContent);
                    this.detector.detectChanges();
                })
        );
    }

    openActivity($event): void {
        $event.stopImmediatePropagation();
        this.markReportAsRead();
        this.libraryService.openActivity(this.entryContent);
    }

    getParentOf($event: Event): void {
        $event.stopImmediatePropagation();
        this.parentOf.emit(this.entryContent);
    }

    isRecentlyModified(): boolean {
        const date = new Date(this.entryContent.timemodified);
        const oneMonth = 1000 * 60 * 60 * 24 * 30;
        return Date.now() - Date.parse(date.toString()) < oneMonth;
    }

    isRecent(): boolean {
        const date = new Date(this.entryContent.timecreated);
        const oneMonth = 1000 * 60 * 60 * 24 * 30;
        return Date.now() - Date.parse(date.toString()) < oneMonth;
    }

    canOpenActivity(): boolean {
        return !this.entryContent.level && this.entryContent.path !== '';
    }

    canNationalize(): boolean {
        return this.loginService.isNationalAdmin() && this.entryContent.localStructure !== null;
    }

    isNationalAdmin(): boolean {
        return this.loginService.isNationalAdmin();
    }

    isLocalAdmin(): boolean {
        return this.loginService.isLocalAdmin();
    }

    sendMailToReportAuthor(): void {
        this.userService.getUser(this.entryContent.report.author.id).subscribe((user: User) => {
            if (!!user.email) {
                window.open(
                    `mailto:${user.email}?subject=Votre signalement sur le contenu ${this.entryContent.title}`
                );
            } else {
                this.dialogService.openWarning("L'utilisateur n'a pas enregistré d'email");
            }
        });
    }

    /**
     * is that content available for edition ?
     * @returns boolean
     */
    isEditable(): boolean {
        let isEditable = false;
        if (this.loginService.isNationalAdmin()) {
            isEditable = true;
        } else if (
            this.loginService.isLocalAdmin() &&
            this.entryContent.localStructure === this.loginService.getUser().localStructure &&
            !this.entryContent.hasprice
        ) {
            isEditable = true;
        } else if (
            this.entryContent.author === this.loginService.getUser().id &&
            this.entryContent.localStructure === this.loginService.getUser().localStructure &&
            !this.entryContent.hasprice
        ) {
            isEditable = true;
        }

        return isEditable && !this.isArchiveView;
    }

    isCopiable(): boolean {
        return (
            (this.loginService.isNationalAdmin() || !this.entryContent.hasprice) &&
            !this.isArchiveView
        );
    }

    isAuthor(): boolean {
        return this.entryContent.author === this.loginService.getUser().id;
    }

    canEnableContent(): boolean {
        return (
            (this.loginService.isNationalAdmin() ||
                (this.entryContent.localStructure && this.loginService.isLocalAdmin()) ||
                this.entryContent.author === this.loginService.getUser().id) &&
            !this.isArchiveView
        );
    }

    canArchiveContent(): boolean {
        return (
            (!this.entryContent.enabled &&
                (this.loginService.isNationalAdmin() ||
                    (this.entryContent.localStructure && this.loginService.isLocalAdmin()))) ||
            (this.isAuthor() && !this.entryContent.enabled)
        );
    }

    canDeleteContent(): boolean {
        return (
            this.isArchiveView &&
            (this.loginService.isNationalAdmin() || this.loginService.isLocalAdmin())
        );
    }

    canReportActivityError(): boolean {
        return !this.entryContent.level && !this.entryContent.localStructure;
    }

    canExportQuiz(): boolean {
        return (
            (this.loginService.getUser().roles.nationalAdmin ||
                this.loginService.getUser().roles.nationalTeacher) &&
            this.entryContent.type === 'quiz'
        );
    }

    canShowReporting(): boolean {
        return !(!this.loginService.getUser().localStructure || this.isArchiveView);
    }

    hasChildren(): boolean {
        if (this.entryContent.children) {
            return this.entryContent.children.length > 0;
        }
        return false;
    }

    entryClickHandler() {
        this.markReportAsRead();
        this.getChildren();
    }

    markReportAsRead() {
        if (this.isReportView && this.entryContent.report.unseen_new_status) {
            this.subscriptions.add(
                this.libraryService
                    .markReportedContentAsSeen(this.entryContent.report.id)
                    .subscribe((data: any) => {
                        if (data) {
                            this.entryContent.report.unseen_new_status = false;
                            this.detector.detectChanges();
                        }
                    })
            );
        }
    }

    getChildren() {
        if (!this.entryContent.children) {
            this.subscriptions.add(
                this.libraryService.getContent(this.entryContent.id).subscribe((data: any) => {
                    this.entryContent.children = data.children;
                    this.entryContent = {
                        ...this.entryContent,
                        children: data.children
                    };
                    this.detector.detectChanges();
                })
            );
        }
    }

    forceGetChildren($event: Event) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.libraryService.getContent(this.entryContent.id).subscribe((data: any) => {
                this.entryContent.children = data.children;
                this.parentOfMode = false;
                this.opened = true;
                this.detector.detectChanges();
            })
        );
    }

    /**
     * returns true if the element is an assembly
     */
    isAssembly(): boolean {
        return (
            this.entryContent.level !== 'activity' &&
            this.entryContent.level !== null &&
            this.entryContent.level !== undefined
        );
    }

    getContentLevel(): string {
        return this.entryContent.level;
    }

    getChildrenContentLevel(): string {
        switch (this.entryContent.level) {
            case 'parcours': {
                return 'bloc';
            }
            case 'bloc': {
                return 'competence';
            }
            case 'competence': {
                return 'module';
            }
            case 'module': {
                return 'sequence';
            }
            case 'sequence': {
                return 'activity';
            }
        }
    }

    isVisibleIconForAction(action: string) {
        switch (action) {
            case 'rateActivity': {
                return !this.isReportView && !this.isAssembly() && !this.entryContent.note_avg;
            }
            case 'disableContent': {
                return this.entryContent.enabled && this.canEnableContent();
            }
            case 'enableContent': {
                return !this.entryContent.enabled && this.canEnableContent();
            }
            case 'archiveContent': {
                return !this.isArchiveView && this.canArchiveContent();
            }
            case 'unarchiveContent': {
                return this.isArchiveView && this.canArchiveContent();
            }
            case 'deleteContent': {
                return this.canDeleteContent();
            }
            case 'reportActivityError': {
                return this.canReportActivityError();
            }
            case 'exportQuiz': {
                return this.canExportQuiz();
            }
            case 'openReporting': {
                return (
                    this.canShowReporting() && !this.loginService.getUser().roles.nationalTeacher
                );
            }
            case 'viewContent': {
                return true;
            }
            case 'viewGuide': {
                return this.canViewGuide();
            }
            case 'copyContent': {
                return this.isCopiable();
            }
            case 'editContent': {
                return this.isEditable();
            }
            case 'nationalizeContent': {
                return this.canNationalize();
            }
            case 'openAdvancedAssignation': {
                return (
                    this.entryContent.enabled && !this.loginService.getUser().roles.nationalTeacher
                );
            }
            case 'addToFavorites': {
                return !this.entryContent.favorite;
            }
            case 'removeFromFavorites': {
                return this.entryContent.favorite;
            }
            case 'openActivity': {
                return this.canOpenActivity();
            }
            case 'getParentOf': {
                return true;
            }
            case 'openViewReport': {
                return this.isReportView;
            }
        }
    }

    isNationalContent() {
        return !this.entryContent.localStructure;
    }

    isVisibleIcon(icon: string) {
        switch (icon) {
            case 'icon-Timer-cercle': {
                return this.showDuration();
            }
            case 'price': {
                return this.entryContent.hasprice !== 0 && this.entryContent.hasprice !== null;
            }
            case 'note-numeric': {
                return !this.isReportView && !this.isAssembly() && this.entryContent.note_avg;
            }
            case 'icon-nouveau': {
                return this.isRecent();
            }
            case 'icon-misajour': {
                return this.isRecentlyModified() && !this.isRecent();
            }
            case 'icon-userinconnu': {
                return this.isAuthor();
            }
            case 'icon-OrdoOn': {
                return this.entryContent.ordered;
            }
            case 'icon-Favoris': {
                return this.entryContent.favorite;
            }
            case 'icon-down': {
                return this.isAssembly() && !this.isParentOf();
            }
            case 'icon-troispoints': {
                return this.isAssembly() && this.isParentOf();
            }
        }
    }

    getReportTooltip() {
        return `Signalement #${this.entryContent.report.id}<br>
                Contenu #${this.entryContent.id} ${this.entryContent.title}<br>
                Reporté par ${this.entryContent.report.author.lastname.toUpperCase()}
                ${this.entryContent.report.author.firstname}
                de la structure ${this.entryContent.localStructure}<br>
                Le ${new Date(this.entryContent.report.timecreated).toLocaleString()}`;
    }

    openViewReport($event: any) {
        $event.stopImmediatePropagation();
        this.markReportAsRead();
        this.subscriptions.add(
            this.dialogService
                .openViewActivityError(this.entryContent, this.loginService.isNationalAdmin())
                .subscribe((data) => {
                    if (data) {
                        this.libraryService.emitRefreshLibrary();
                    }
                })
        );
        if (this.isNationalAdmin() && this.entryContent.report.status === ReportStatus.created) {
            // si nationalAdmin et statut créé, passage auto à statut inprogress
            this.subscriptions.add(
                this.libraryService
                    .updateActivityReportStatus(
                        this.entryContent.report.id,
                        ReportStatus.inprogress
                    )
                    .subscribe((data: any) => {
                        this.entryContent.report.status = ReportStatus.inprogress;
                        this.detector.detectChanges();
                    })
            );
        }
    }

    emitParentOf(child: Content): void {
        this.parentOf.emit(child);
    }

    toggleIcon() {
        this.opened = !this.opened;
    }

    isParentOf() {
        if (this.parentOfMode) {
            if (!this.entryContent.children) {
                this.parentOfMode = false;
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    startDrag() {
        this.assignmentService.setDraggedElement(this.entryContent);
        this.assignmentService.setDraggedMode('COPY');
        this.detector.detectChanges();
    }

    endDrag() {
        this.assignmentService.clearDraggedElement();
        this.assignmentService.clearDraggedMode();
        this.detector.detectChanges();
    }

    /******************MULTISELECTION******************/

    selectContent($event: Event) {
        $event.stopPropagation();
        if (!this.multiselectService.isContentSelected(this.entryContent)) {
            this.multiselectService.addContentToSelection(this.entryContent);
        } else {
            this.multiselectService.removeContentFromSelection(this.entryContent);
        }
        this.detector.detectChanges();
    }

    /*################################################################*
     *#                           NOTATION                           #*
     *################################################################*/

    rateActivity($event: Event): void {
        $event.stopPropagation();
        this.subscriptions.add(
            this.dialogService.openActivityRating(this.entryContent).subscribe((data: any) => {
                if (data) {
                    this.flashMessageService.flash('Votre note a été prise en compte.');
                    this.libraryService
                        .getContent(this.entryContent.id, false)
                        .subscribe((updatedContent: Content) => {
                            this.entryContent = updatedContent;
                            this.detector.detectChanges();
                        });
                }
            })
        );
    }

    getFormattedNote(note: string): string {
        return note.toString().replace('.', ',');
    }

    getNoteTooltip(entryContent: Content): SafeHtml {
        return `<strong>${entryContent.note_count}</strong> note${
            +entryContent.note_count > 1 ? 's' : ''
        } : moyenne <strong>${entryContent.note_avg}</strong>/5`;
    }

    getBackgroundGradient(note: string): SafeStyle {
        // le gradient est "efficace entre 22% et 82%
        const grad = note ? 22 + (+note * 60) / 5 : 0;
        return `linear-gradient(90deg, #007fad ${grad}%, transparent ${grad}%)`;
    }
}
