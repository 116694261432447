<div class="header">
    <span class="icon-ajouter-contenus"></span>
    <div class="title">{{ getTitle() }}</div>
    <span class="icon-close" (click)="closeDialogWithoutUpdate()"></span>
</div>
<div class="content" *ngIf="!isLoading('contentCreationBody')">
    <div class="extra-container" *ngIf="showExtraContainer()">
        <ng-container *ngIf="showFileContainer()">
            <div class="element">
                <div class="label">{{ content.type === 'devoir' ? 'Énoncé' : 'Fichier' }}</div>
                <div class="legend">Import limité à <b>200 Mo</b></div>

                <div
                    class="value"
                    [ngClass]="{ 'no-file': !hasFile() }"
                    appFileDropzone
                    (fileDropped)="uploadFile($event)"
                >
                    {{ getCurrentFileLabel() }}
                </div>
                <div
                    class="icon-Importer"
                    (click)="openSelectFile()"
                    [tooltip]="getFileTooltip()"
                ></div>
                <input
                    class="hidden"
                    type="file"
                    #fileInput
                    (change)="uploadFile($event.target.files)"
                />

                <div class="sublegend" *ngIf="!content.path && !file">
                    Vous pouvez importer n'importe quel fichier, toutefois, nous préconisons les
                    types de contenus suivants : <br />
                    <b>scorm</b> : paquet formaté suivant cette norme de contenus de formation<br />
                    <b>pdf</b> : fichier passif lisible sur quasiment tous les supports, et prévu
                    pour être aisément imprimable<br />
                    <b>office</b> ou <b>open office, articulate</b>
                </div>

                <div class="table-of-contents" *ngIf="content.type === 'scorm'">
                    <div
                        class="icon"
                        [ngClass]="{
                            'icon-select': !content.tableOfContents,
                            'icon-selected': content.tableOfContents
                        }"
                        (click)="toggleScormMenu()"
                    ></div>
                    <div class="text">Afficher le menu du SCORM aux apprenants</div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="showURLContainer()">
            <div class="element">
                <div class="label">Adresse du lien web</div>
                <div class="legend">Assurez-vous d'avoir les droits d'utilisation du lien web</div>
                <input
                    type="text"
                    placeholder="Adresse du lien web (250 caractères maximum)"
                    [(ngModel)]="content.url"
                />
            </div>
        </ng-container>

        <ng-container *ngIf="showLTIContainer()">
            <div class="element">
                <div class="label">Adresse de l'activité LTI (URL)</div>
                <input
                    type="text"
                    placeholder="Adresse du lien web (250 caractères maximum)"
                    [(ngModel)]="content.lti_url"
                />
            </div>
            <div class="element">
                <div class="label">Clé de la ressource LTI</div>
                <input
                    type="text"
                    placeholder="Clé de la ressource LTI"
                    [(ngModel)]="content.lti_resourcekey"
                />
            </div>
            <div class="element">
                <div class="label">Clé de l'activité LTI</div>
                <input type="text" placeholder="Clé secrète" [(ngModel)]="content.lti_key" />
            </div>
            <div class="element">
                <div class="label">Paramètres de l'activité LTI</div>
                <textarea
                    type="text"
                    placeholder="Paramètres de l'activité LTI"
                    [(ngModel)]="content.lti_params"
                ></textarea>
            </div>
        </ng-container>

        <ng-container *ngIf="showN4SContainer()">
            <div class="element">
                <div class="label">Code Num4Skills</div>
                <input type="text" placeholder="Code Num4Skills" [(ngModel)]="content.data" />
            </div>
        </ng-container>

        <ng-container *ngIf="showH5PContainer()">
            <div class="element">
                <div class="label">Type de contenu H5P</div>
                <lib-uimm-dropdown
                    [(items)]="h5pTypes"
                    [label]="'Type H5P'"
                    [labelWidth]="'100%'"
                    [uniformWidth]="true"
                    (itemsChange)="updateH5PType()"
                    [headers]="{ search: true }"
                ></lib-uimm-dropdown>
            </div>
            <div class="element">
                <div class="label">Fichier H5P</div>
                <div class="value" appFileDropzone (fileDropped)="uploadH5PContent($event)">
                    {{ getCurrentH5PLabel() }}
                </div>
                <div
                    class="icon-Importer"
                    *ngIf="!h5pContent"
                    (click)="openSelectH5PContent()"
                    [tooltip]="'Ajouter un fichier H5P'"
                ></div>
                <div
                    class="icon-bin"
                    *ngIf="h5pContent"
                    (click)="deleteH5PContent()"
                    [tooltip]="'Supprimer le fichier H5P'"
                ></div>
                <input
                    class="hidden"
                    type="file"
                    #h5pContentInput
                    (change)="uploadH5PContent($event.target.files)"
                />
            </div>
        </ng-container>

        <ng-container *ngIf="showManuelContainer()">
            <div class="element" *ngIf="showManuelType()">
                <div class="label">Type de manuel</div>
                <lib-uimm-dropdown
                    [(items)]="manuelTypes"
                    [label]="'Type'"
                    [labelWidth]="'100%'"
                    [labelDefaultColor]="'#ff7200'"
                    [uniformWidth]="true"
                    (itemsChange)="updateType()"
                ></lib-uimm-dropdown>
            </div>
            <div class="element" *ngIf="content.type === 'hachette'">
                <div class="label">Code ressource</div>
                <input
                    type="text"
                    placeholder="Code ressource"
                    [(ngModel)]="content.code_ressource"
                />
            </div>
            <div class="element" *ngIf="showLocalDropdown() && content.type === 'hachette'">
                <div class="label">Code établissement UAI</div>
                <input
                    type="text"
                    placeholder="Code établissement UAI"
                    [(ngModel)]="content.code_uai"
                />
            </div>
            <div class="element" *ngIf="content.type === 'nathan'">
                <div class="label">URL du manuel Nathan</div>
                <input type="text" placeholder="URL du manuel Nathan" [(ngModel)]="content.url" />
            </div>
        </ng-container>
    </div>

    <div class="separator" *ngIf="showExtraContainer()"></div>

    <div class="category-container">
        <div class="element" *ngIf="!content.type">
            <div class="label">Niveau de l'assemblage*</div>
            <lib-uimm-dropdown
                *ngIf="!content.id"
                [label]="'Choisir un niveau'"
                [labelDefaultColor]="'#ff7200'"
                [(items)]="levels"
                [labelWidth]="'200px'"
                [uniformWidth]="true"
            ></lib-uimm-dropdown>
            <div *ngIf="content.id">
                <div class="icon" [ngClass]="getContentIcon()"></div>
                <div class="level">{{ getContentLabel() }}</div>
            </div>
        </div>
        <div class="element" *ngIf="content.type">
            <div class="label">Catégorie *</div>
            <lib-uimm-dropdown
                [label]="'Choisir une catégorie'"
                [labelDefaultColor]="'#ff7200'"
                [(items)]="categories"
                [labelWidth]="'200px'"
                [uniformWidth]="true"
            ></lib-uimm-dropdown>
        </div>
        <div class="element" *ngIf="content.type">
            <div class="label">
                Durée *
                <div
                    class="icon icon-Aide"
                    [tooltip]="
                        'Heures:Minutes > 00:00<br>Heures jusqu\'à 9999<br>Minutes jusqu\'à 59'
                    "
                ></div>
            </div>
            <input
                type="text"
                placeholder="Durée de l'activité (hhhh:mm)"
                [(ngModel)]="content.duration"
                [ngClass]="{ 'invalid-duration': !isValidDuration() }"
            />
        </div>
    </div>

    <div class="separator"></div>

    <div class="restriction-container">
        <lib-uimm-dropdown
            [(items)]="restriction"
            [label]="'Restriction'"
            [labelWidth]="'100%'"
            [labelDefaultColor]="'#ff7200'"
            [uniformWidth]="true"
            (itemsChange)="updateRestriction()"
        ></lib-uimm-dropdown>
        <lib-uimm-dropdown
            *ngIf="showLocalDropdown()"
            [(items)]="structures"
            [label]="'Structure'"
            [labelWidth]="'100%'"
            [labelDefaultColor]="'#ff7200'"
            [uniformWidth]="true"
            (itemsChange)="updateSite()"
        ></lib-uimm-dropdown>
        <lib-uimm-dropdown
            *ngIf="showSiteDropdown()"
            [(items)]="sites"
            [label]="'Site'"
            [labelWidth]="'100%'"
            [labelDefaultColor]="'#ff7200'"
            [uniformWidth]="true"
            [multiselect]="true"
        ></lib-uimm-dropdown>
    </div>

    <div class="separator"></div>

    <div class="main-container">
        <div class="element">
            <div class="label">Nom *</div>
            <input
                class="value"
                type="text"
                placeholder="Nom du contenu (250 caractères maximum)"
                [(ngModel)]="content.title"
            />
        </div>
        <div class="element">
            <div class="label">Description *</div>
            <textarea
                class="value"
                placeholder="Description (650 caractères maximum)"
                [(ngModel)]="content.description"
            ></textarea>
        </div>
        <div class="element">
            <div class="label">Domaine *</div>
            <lib-uimm-dropdown
                class="value"
                [(items)]="domains"
                [multiselect]="true"
                [label]="getDomainsDropDownLabel()"
                [labelWidth]="'150px'"
                [labelDefaultColor]="'#ff7200'"
                [headers]="{ search: true, count: true }"
            ></lib-uimm-dropdown>
        </div>
        <div class="element">
            <div class="label">Mot clés *</div>
            <input
                class="value"
                type="text"
                placeholder="Saisir les mots clés séparés par des ; (10 maximum)"
                [(ngModel)]="content.keywords"
            />
        </div>
        <div
            class="mark-as-completed"
            *ngIf="content.type !== 'scorm' && content.type !== 'quiz' && !isAssembly()"
        >
            <div
                class="icon"
                [ngClass]="{
                    'icon-select': !content.mark_as_completed_on_open,
                    'icon-selected': content.mark_as_completed_on_open
                }"
                (click)="toggleMarkAsCompletedOnOpen()"
            ></div>
            <div class="text">Marquer comme terminé à l'ouverture</div>
        </div>
        <div class="visible-for-all" *ngIf="showVisibleForAll()">
            <div
                class="icon"
                [ngClass]="{
                    'icon-select': content.visible_for_all,
                    'icon-selected': !content.visible_for_all
                }"
                (click)="toggleVisibleForAll()"
            ></div>
            <div class="text">Masquer ce contenu aux autres formateurs</div>
        </div>
        <div class="legend">* Champs obligatoires</div>
    </div>

    <div class="separator"></div>

    <mat-expansion-panel
        class="facultative-container"
        [ngClass]="{ isopened: facultativeContainerOpened }"
        (afterExpand)="facultativeContainerOpened = true"
        (closed)="facultativeContainerOpened = false"
    >
        <mat-expansion-panel-header
            class="legend"
            [collapsedHeight]="'30px'"
            [expandedHeight]="'30px'"
            >Champs facultatifs</mat-expansion-panel-header
        >
        <div class="element">
            <div class="label">Guide</div>
            <div class="value" appFileDropzone (fileDropped)="uploadGuide($event)">
                {{ getCurrentGuideLabel() }}
            </div>
            <div
                class="icon-Importer"
                *ngIf="!content.guide && !guide"
                (click)="openSelectGuide()"
                [tooltip]="'Ajouter un guide'"
            ></div>
            <div
                class="icon-bin"
                *ngIf="content.guide || guide"
                (click)="deleteGuide()"
                [tooltip]="'Supprimer un guide'"
            ></div>
            <input
                class="hidden"
                type="file"
                #guideInput
                (change)="uploadGuide($event.target.files)"
            />
        </div>
        <div class="element">
            <div class="label">Code</div>
            <input
                type="text"
                placeholder="Choisir un code pour ce contenu"
                [(ngModel)]="content.ucode"
            />
        </div>
        <div class="element">
            <div class="label">Taxonomie</div>
            <lib-uimm-dropdown
                [label]="'Taxonomies'"
                [(items)]="taxonomies"
                [labelWidth]="'100%'"
                [uniformWidth]="true"
            ></lib-uimm-dropdown>
        </div>
        <div class="element">
            <div class="label">Fournisseur</div>
            <lib-uimm-dropdown
                [label]="'Fournisseurs'"
                [(items)]="providers"
                [labelWidth]="'100%'"
                [maxHeight]="200"
                [uniformWidth]="true"
            ></lib-uimm-dropdown>
        </div>
        <div class="element">
            <div class="label">Copyright</div>
            <input
                type="text"
                placeholder="Choisir un copyright pour ce contenu"
                [(ngModel)]="content.copyright"
            />
        </div>
        <div class="element" *ngIf="isNationalAdmin()">
            <div class="label">Payant</div>
            <lib-uimm-dropdown
                [(items)]="hasprice"
                [labelWidth]="'100%'"
                [uniformWidth]="true"
            ></lib-uimm-dropdown>
        </div>
        <div class="element" *ngIf="showPriceInput()">
            <div class="label">Prix *</div>
            <input type="text" *ngIf="showPriceInfo()" [(ngModel)]="content.pricetxt" />
            <input type="number" *ngIf="showPriceNumber()" [(ngModel)]="content.price" />
        </div>
        <div class="element">
            <div class="label">Peut être forcé</div>
            <lib-uimm-dropdown
                [(items)]="forceStatus"
                [labelWidth]="'100%'"
                [uniformWidth]="true"
            ></lib-uimm-dropdown>
        </div>
        <div class="element" *ngIf="isAdmin()">
            <div class="label">Formation de formateur</div>
            <lib-uimm-dropdown
                [(items)]="trainingOfTeachers"
                [labelWidth]="'100%'"
                [uniformWidth]="true"
            ></lib-uimm-dropdown>
        </div>
        <div class="element" *ngIf="canShowCertificate() && isAssembly()">
            <div class="label">Avec attestation <sup>*Champ CODE obligatoire</sup></div>
            <lib-uimm-dropdown
                [disabled]="!content.ucode"
                [(items)]="hasCertificate"
                [labelWidth]="'100%'"
                [uniformWidth]="true"
            ></lib-uimm-dropdown>
        </div>
    </mat-expansion-panel>

    <div class="separator"></div>

    <mat-expansion-panel
        class="facultative-container"
        *ngIf="isAdmin()"
        [ngClass]="{ isopened: catalogContainerOpened }"
        (afterExpand)="catalogContainerOpened = true"
        (closed)="catalogContainerOpened = false"
    >
        <mat-expansion-panel-header
            class="legend"
            [collapsedHeight]="'30px'"
            [expandedHeight]="'30px'"
        >
            Catalogue
        </mat-expansion-panel-header>
        <div class="element">
            <div class="label">Visuel</div>
            <div class="value" appFileDropzone (fileDropped)="uploadVisuel($event)">
                {{ getCurrentVisuelLabel() }}
            </div>
            <div
                class="icon-Importer"
                *ngIf="!content.visuel && !visuel"
                (click)="openSelectVisuel()"
                [tooltip]="'Ajouter un visuel'"
            ></div>
            <div
                class="icon-bin"
                *ngIf="content.visuel || visuel"
                (click)="deleteVisuel()"
                [tooltip]="'Supprimer un visuel'"
            ></div>
            <input
                class="hidden"
                type="file"
                #visuelInput
                (change)="uploadVisuel($event.target.files)"
            />
        </div>
        <div class="element">
            <div class="label">Titre (forcé)</div>
            <input type="text" placeholder="Titre" [(ngModel)]="content.forced_title" />
        </div>
        <div class="element">
            <div class="label">Durée (forcée)</div>
            <input
                type="text"
                placeholder="Durée (texte libre)"
                [(ngModel)]="content.forced_duration"
            />
        </div>
        <div class="element">
            <div class="label">Information</div>
            <input
                type="text"
                placeholder="Information (650 caractères maximum)"
                [(ngModel)]="content.information"
            />
        </div>
        <div class="element">
            <div class="label">Nouveau</div>
            <lib-uimm-dropdown
                [(items)]="newCatalog"
                [labelWidth]="'100%'"
                [uniformWidth]="true"
            ></lib-uimm-dropdown>
        </div>
        <div class="element">
            <div class="label">Visible</div>
            <lib-uimm-dropdown
                [(items)]="visibleCatalog"
                [labelWidth]="'100%'"
                [uniformWidth]="true"
            ></lib-uimm-dropdown>
        </div>
        <div class="element">
            <div class="label">Bandeau (forcé)</div>
            <lib-uimm-dropdown
                [(items)]="forcedStripe"
                [labelWidth]="'100%'"
                [uniformWidth]="true"
            ></lib-uimm-dropdown>
        </div>
        <div class="element" *ngIf="isForcedStripe()">
            <div class="label"></div>
            <input
                type="text"
                placeholder="Texte du bandeau (20 caractères maximum)"
                [(ngModel)]="content.forced_stripe_text"
            />
        </div>
        <div class="element" *ngIf="isForcedStripe()">
            <div class="label"></div>
            <div class="radio-container">
                <div
                    *ngFor="let color of stripeColors"
                    class="radio"
                    (click)="selectStripeColor(color)"
                    [ngClass]="{ selected: isSelectedStripeColor(color) }"
                >
                    <div [ngStyle]="{ 'background-color': color }"></div>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</div>

<div class="content loading" *ngIf="isLoading('contentCreationBody')">
    <app-loading></app-loading>
</div>
<div class="footer">
    <div
        class="button"
        [ngClass]="{ disabled: !canSaveContent() }"
        *ngIf="!isLoading('contentCreationFooter') && !content.with_publish_option"
        (click)="saveContent()"
    >
        Enregistrer
    </div>
    <div
        class="button"
        [ngClass]="{ disabled: !canPublishContent() }"
        *ngIf="!isLoading('contentCreationFooter') && content.with_publish_option"
        (click)="saveContent()"
    >
        Publier
    </div>
    <div class="button" *ngIf="isLoading('contentCreationFooter')"><app-loading></app-loading></div>
</div>
