<div class="header">
    <div class="icon-ajouter-contenus"></div>
    <div class="title">Création de contenu</div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>
<div class="content">
    <div class="container">
        <div class="container-header">
            <div class="title">Assemblage</div>
            <div class="legend">Construisez un ensemble ordonné de contenus de formation</div>
        </div>
        <div class="element" (click)="select('assembly')">
            <span class="icon icon-creer-assemblage"></span>
            <div class="element-container">
                <div class="element-title">Créer un assemblage</div>
                <div class="element-legend">
                    Assemblez des contenus au sein de
                    <b>parcours, bloc, compétence, module ou de séquence</b>
                </div>
            </div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="container">
        <div class="container-header">
            <div class="title">Importation ou création de contenus</div>
            <div class="legend">
                Importez un contenu, ou créez le grâce aux outils de création d'<b>easi</b>
            </div>
        </div>
        <div class="element" (click)="select('import')">
            <span class="icon icon-download"></span>
            <div class="element-container">
                <div class="element-title">Importer un fichier</div>
                <div class="element-legend">
                    Formats acceptés : <b>scorm, pdf, vidéos, documents office</b>
                </div>
            </div>
        </div>
        <div class="element" (click)="select('devoir')">
            <span class="icon icon-devoir-actif"></span>
            <div class="element-container">
                <div class="element-title">Créer une activité de type remise de devoir</div>
                <div class="element-legend">
                    Formats acceptés : <b>pdf, vidéos, documents office</b>
                </div>
            </div>
        </div>
        <div class="element" (click)="select('h5p')">
            <span class="icon icon-easi-media-line"></span>
            <div class="element-container">
                <div class="element-title media">Créer un média</div>
                <div class="element-legend">
                    Créez des
                    <b>présentations interactives, frises chronologiques, vidéos interactives</b>
                </div>
            </div>
        </div>
        <div class="element" (click)="select('quiz')">
            <span class="icon icon-easi-media-line"></span>
            <div class="element-container">
                <div class="element-title media">Créer un test</div>
                <div class="element-legend">
                    Créez un
                    <b>test avec l'outil de création EASi</b>
                </div>
            </div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="container">
        <div class="container-header">
            <div class="title">Activités sans contenu</div>
            <div class="legend">
                Déclarez dans <b>easi</b> une activité pour vos apprenants sans créer de contenu
            </div>
        </div>
        <div class="element" (click)="select('presentiel')">
            <span class="icon icon-activite-presentielle-cercle"></span>
            <div class="element-container">
                <div class="element-title">Créer une activité sans contenu</div>
                <div class="element-legend">
                    Déclarez des <b>activités présentielles</b> ou des
                    <b>travaux pratique présentiels</b>
                </div>
            </div>
        </div>
        <div class="element" (click)="select('url')">
            <span class="icon icon-lien-cercle"></span>
            <div class="element-container">
                <div class="element-title">Créer une activité de type lien web</div>
                <div class="element-legend">
                    Créez une activité en renseignant une adresse pointant vers une <b>page web</b>
                </div>
            </div>
        </div>
        <div class="element" (click)="select('partenariat')">
            <span class="icon icon-lien-cercle"></span>
            <div class="element-container">
                <div class="element-title">Créer une activité de type partenariat</div>
                <div class="element-legend">
                    Créez une activité en renseignant une adresse pointant vers une
                    <b>page web partenaire</b>
                </div>
            </div>
        </div>
        <div class="element" (click)="select('lti')" *ngIf="isNationalAdmin()">
            <span class="icon icon-activite-cercle"></span>
            <div class="element-container">
                <div class="element-title">Créer une activité LTI</div>
                <div class="element-legend">
                    Créer une activite Learning Tools Interoperability en renseignant
                    <b>une url, une clé secrète et les paramètres</b>
                </div>
            </div>
        </div>
        <div class="element" (click)="select('manuel')">
            <span class="icon icon-Loi"></span>
            <div class="element-container">
                <div class="element-title">Créer un manuel numérique</div>
                <div class="element-legend">
                    Créer un manuel numérique sur base d'une <b>URL</b> et d'un
                    <b>code établissement (UAI)</b>
                </div>
            </div>
        </div>
        <div class="element" (click)="select('n4s')" *ngIf="isNationalAdmin()">
            <span class="icon icon-Loi"></span>
            <div class="element-container">
                <div class="element-title">Créer un positionnement Num4Skills</div>
                <div class="element-legend">
                    Créer un positionnement Num4Skills sur base d'un <b>code</b>
                </div>
            </div>
        </div>
    </div>
    <div class="container" *ngIf="isNationalAdmin()">
        <div class="container-header">
            <div class="title">Import en masse</div>
            <div class="legend">Importez vos activités ou vos assemblages en masse</div>
        </div>
        <div class="element" (click)="select('mass_assembly')">
            <span class="icon icon-download"></span>
            <div class="element-container">
                <div class="element-title">Import d'assemblages</div>
                <div class="element-legend">Formats acceptés : <b>csv</b></div>
                <a
                    class="element-link"
                    (click)="$event.stopPropagation()"
                    href="https://cdn.easi-training.fr/assets/import/import_assembly.csv"
                    >Télécharger le fichier de référence</a
                >
            </div>
        </div>
        <div class="element" (click)="select('mass_activity')">
            <span class="icon icon-download"></span>
            <div class="element-container">
                <div class="element-title">Import d'activité</div>
                <div class="element-legend">Formats acceptés : <b>csv</b></div>
                <a
                    class="element-link"
                    (click)="$event.stopPropagation()"
                    href="https://cdn.easi-training.fr/assets/import/import_activity.csv"
                    >Télécharger le fichier de référence</a
                >
            </div>
        </div>
    </div>
    <div class="container" *ngIf="isAdmin()">
        <div class="container-header">
            <div class="title">Mise à jour de contenus en masse</div>
            <div class="legend">Mettez à jour vos contenus en masse</div>
        </div>
        <div class="element" (click)="select('mass_content_update')">
            <span class="icon icon-download"></span>
            <div class="element-container">
                <div class="element-title">Modifier des contenus en masse</div>
                <div class="element-legend">
                    Formats acceptés : <b>csv (à partir d'un export de bibliothèque)</b>
                </div>
                <a
                    class="element-link"
                    (click)="$event.stopPropagation()"
                    target="_blank"
                    href="https://cdn.easi-training.fr/assets/import/mise_a_jour_de_contenus.pdf"
                    >Télécharger le fichier explicatif des en-têtes</a
                >
            </div>
        </div>
    </div>
</div>
