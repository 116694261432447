import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { LoginService } from '../../services/login.service';
import { LoadingService } from '../../services/loading.service';
import { LibraryService } from '../../services/library.service';
import { DialogService } from '../../services/dialog.service';

import { Content } from '../../structures/content';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-h5p-player',
    templateUrl: './h5p-player.component.html',
    styleUrls: ['./h5p-player.component.scss']
})
export class H5PPlayerComponent implements OnInit {
    constructor(
        private loadingService: LoadingService,
        private libraryService: LibraryService,
        private loginService: LoginService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer
    ) {}

    editing: boolean = false;
    content: Content;
    iframe_url: SafeResourceUrl;
    private subscriptions$: Subscription = new Subscription();

    ngOnInit() {
        this.loadingService.startLoading('h5pPlayer');
        this.subscriptions$.add(
            this.libraryService
                .getContent(+this.route.snapshot.paramMap.get('activityId'))
                .subscribe((data: Content) => {
                    this.content = data;
                    this.setFrameSrc();
                    this.loadingService.stopLoading('h5pPlayer');
                })
        );
    }

    isTeacher() {
        return !this.loginService.isLearner();
    }

    setFrameSrc() {
        if (this.editing) {
            this.iframe_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.content.edit_path);
        } else {
            this.iframe_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.content.path);
        }
    }

    getIcon(): string {
        return this.libraryService.getDraftIcon(this.content);
    }

    getDescription() {
        return this.content.description;
    }

    isLoading() {
        return this.loadingService.isLoading('h5pPlayer');
    }
}
