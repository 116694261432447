<div class="draft-container">
    <div class="header" [ngClass]="{ 'match-search': matchSearch() }">
        <div class="icon" [ngClass]="getIcon()"></div>
        <div class="title" [tooltip]="getDraftTooltip()" tooltipPosition="below-right">
            <div class="name">{{ activity.title }}</div>
            <div class="type" *ngIf="activity.type">
                ({{ activity.type === 'quiz' ? 'test' : activity.type }})
            </div>
        </div>
        <div class="icons">
            <div class="hover-icons">
                <div
                    class="icon-Fiche"
                    (click)="viewFiche($event)"
                    [tooltip]="'Editer la fiche'"
                ></div>
                <div
                    class="icon-guide"
                    *ngIf="activity.guide"
                    (click)="viewGuide($event)"
                    [tooltip]="'Voir le guide'"
                ></div>
                <div
                    class="icon-easi-media-line"
                    *ngIf="showMediaIcon()"
                    (click)="goToMedia($event)"
                    [tooltip]="'Aller dans Media'"
                ></div>
                <div
                    class="icon-Cloner"
                    *ngIf="showCloneIcon()"
                    (click)="cloneDraft($event)"
                    [tooltip]="'Dupliquer le brouillon'"
                ></div>
                <div
                    class="icon-bin"
                    (click)="deleteDraft($event)"
                    [tooltip]="'Supprimer d\'easi training'"
                ></div>
                <div
                    class="icon-Publier"
                    *ngIf="canPublish()"
                    (click)="publishDraft($event)"
                    [tooltip]="'Publier'"
                ></div>
                <div
                    class="icon-envoyer-valideur"
                    *ngIf="canSendToValidation()"
                    (click)="sendDraftToValidation($event)"
                    [tooltip]="'Envoyer en validation'"
                ></div>
                <div
                    class="icon-historique"
                    *ngIf="showHistoryIcon()"
                    (click)="openHistoryDialog($event)"
                    [tooltip]="'Voir l\'historique'"
                ></div>
                <div
                    class="icon-Favoris"
                    *ngIf="!activity.favorite"
                    (click)="addToFavorites($event)"
                    [tooltip]="'Ajouter aux favoris'"
                ></div>
                <div
                    class="icon-Retirerdesfavoris"
                    *ngIf="activity.favorite"
                    (click)="removeFromFavorites($event)"
                    [tooltip]="'Retirer des favoris'"
                ></div>
                <div
                    class="icon-Editer"
                    *ngIf="activity.type === 'quiz'"
                    (click)="editActivity($event)"
                    [tooltip]="'Modifier l\'activité'"
                ></div>
                <div
                    class="icon-voir"
                    (click)="openActivity($event)"
                    [tooltip]="'Voir l\'activité'"
                ></div>
            </div>
            <div class="default-icons">
                <div
                    class="icon-Warning"
                    *ngIf="!canPublishQuiz()"
                    [tooltip]="
                        'Ce contenu ne peut pas être publié car le test associé contient des erreurs.'
                    "
                ></div>
                <div
                    class="icon-Warning"
                    *ngIf="activity.rejected"
                    [tooltip]="'Ce contenu a été rejeté, consultez l\'historique'"
                ></div>
                <div
                    class="icon-Favoris"
                    *ngIf="activity.favorite"
                    [tooltip]="'Contenu favori'"
                ></div>
            </div>
        </div>
    </div>
</div>
