import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import { ConfigService } from '../../../../services/config.service';
import { LoginService } from '../../../../services/login.service';
import { DialogService } from '../../../../services/dialog.service';
import { LibraryService } from '../../../../services/library.service';
import { AssignmentService } from '../../../../services/assignment.service';
import { FlashMessageService } from '../../../../services/flash-message.service';
import { UserService } from '../../../../services/user.service';
import { MultiselectService } from '../../../../services/library-multiselect.service';

import { User } from '../../../../structures/user';
import { Assignment } from '../../../../structures/assignment';
import { DatesPipe } from 'src/app/pipes/dates.pipe';
import { UntilDestroy } from '@ngneat/until-destroy';

import * as FileSaver from 'file-saver';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-user-assignments-entry',
    templateUrl: './user-assignments-entry.component.html',
    styleUrls: ['./user-assignments-entry.component.scss'],
    providers: [DatesPipe]
})
export class UserAssignmentsEntryComponent implements OnInit {
    @Input() user: User;
    @Input() referents: Array<User>;
    @Input() assignment: Assignment;
    @Input() parent: Assignment;
    @Input() index: number;

    @Output() childrenAssigned: EventEmitter<Assignment> = new EventEmitter();
    @Output() childrenMoved: EventEmitter<Assignment> = new EventEmitter();
    @Output() assignmentDeleted: EventEmitter<Assignment> = new EventEmitter();
    @Output() assignmentStatusChanged: EventEmitter<Assignment> = new EventEmitter();

    subscriptions = new Subscription();

    heritedDisponibility: boolean;
    opened: boolean;

    quizList: Array<number>;

    notePanelOpened: boolean;
    tooltipMessage: string;
    isDragging = false;

    constructor(
        private configService: ConfigService,
        private loginService: LoginService,
        private dialogService: DialogService,
        private libraryService: LibraryService,
        private assignmentService: AssignmentService,
        private flashMessageService: FlashMessageService,
        private userService: UserService,
        private multiselectService: MultiselectService,
        private datesPipe: DatesPipe
    ) {}

    ngOnInit() {
        if (this.assignment && this.assignment.note === undefined) {
            this.assignment.note = 0;
        }
        if (this.assignment && this.parent) {
            if (!this.assignment.timestart && this.parent.timestart) {
                this.assignment.timestart = this.parent.timestart;
                if (!this.assignment.timeend) {
                    this.heritedDisponibility = true;
                }
            }
            if (!this.assignment.timeend && this.parent.timeend) {
                this.assignment.timeend = this.parent.timeend;
                if (!this.assignment.timestart) {
                    this.heritedDisponibility = true;
                }
            }
        }
        this.subscriptions.add(
            this.assignmentService.refreshTracking.subscribe((assignments) => {
                this.updateTrackingRecursive(assignments);
            })
        );

        this.subscriptions.add(
            this.assignmentService.refreshDisponibility.subscribe((assignments) => {
                this.updateDisponibilityRecursive(assignments);
            })
        );
    }

    getIcon(): string {
        if (this.multiselectService.isAssignmentSelected(this.assignment)) {
            return 'icon-Valide selected';
        } else {
            return this.libraryService.getIcon(this.assignment);
        }
    }

    isFirstElement(): boolean {
        return this.index === 0;
    }

    isGroupAssignment(): boolean {
        if (this.assignment) {
            return this.assignment.enrolmentSource !== 'individual';
        }
    }

    isTutor(): boolean {
        return this.loginService.getUser().roles.tutor;
    }

    startDrag() {
        this.assignmentService.setDraggedElement(this.assignment);
        this.assignmentService.setDraggedMode('MOVE');
    }

    endDrag() {
        this.assignmentService.clearDraggedElement();
        this.assignmentService.clearDraggedMode();
    }

    preventAssignment() {
        if (this.assignmentService.getDraggedElement()) {
            return !this.canAssignContent();
        } else {
            return false;
        }
    }

    preventAssignmentInParent() {
        if (this.assignmentService.getDraggedElement()) {
            return !this.canAssignContentInParent();
        } else {
            return false;
        }
    }

    canAssignContent() {
        if (this.assignmentService.getDraggedMode() === 'COPY') {
            if (this.isGroupAssignment()) {
                return false;
            }
            if (this.assignmentService.getDraggedElement() && !this.assignment.hasprice) {
                return this.assignmentService.canBeChild(this.assignment);
            }
        } else if (this.assignmentService.getDraggedMode() === 'MOVE') {
            return (
                this.assignment.assignmentId === this.assignmentService.getDraggedElement().parents
            );
        }
    }

    canAssignContentInParent() {
        if (this.assignmentService.getDraggedMode() === 'COPY') {
            if (this.isGroupAssignment()) {
                return false;
            }
            if (
                this.assignmentService.getDraggedElement() &&
                this.parent &&
                !this.parent.hasprice
            ) {
                if (this.parent.assignmentId) {
                    return this.assignmentService.canBeChild(this.parent);
                } else {
                    return true;
                }
            }
            return true;
        } else if (this.assignmentService.getDraggedMode() === 'MOVE') {
            return this.assignment.parents === this.assignmentService.getDraggedElement().parents;
        }
    }

    getForbiddenAssignmentTooltipMessage(): string {
        if (this.assignmentService.getDraggedMode() === 'COPY') {
            if (this.isGroupAssignment()) {
                return 'Vous ne pouvez rien déposer sur une assignation de groupe';
            } else if (this.assignment.hasprice) {
                return 'Vous ne pouvez rien déposer sur cette assignation';
            } else if (!this.canAssignContent()) {
                switch (this.assignment.category) {
                    case 'positionnement':
                    case 'preparation':
                    case 'ressource':
                    case 'travail':
                    case 'evaluation':
                    case 'corrige':
                    case 'tp':
                    case 'presentiel':
                    case 'guide':
                    case 'url':
                    case 'devoir':
                        return 'Vous ne pouvez rien déposer sur une activité';
                    default: {
                        switch (this.assignment.level) {
                            case 'parcours':
                                return "Seuls les blocs, les compétences et les modules peuvent être déposés à la racine d'un parcours";
                                break;
                            case 'bloc':
                                return "Seuls les compétences et les modules peuvent être déposés à la racine d'un bloc";
                                break;
                            case 'competence':
                                return "Seuls les modules, les séquences et les activités peuvent être déposés à la racine d'une compétence";
                                break;
                            case 'module':
                                return 'Seules les séquences et les activités peuvent être déposées dans un module';
                                break;
                            case 'sequence':
                                return 'Seules les activités peuvent être déposées dans une séquence';
                                break;
                            case 'activity':
                                return 'Vous ne pouvez rien déposer sur une activité.';
                            default:
                                break;
                        }
                    }
                }
            } else {
                return undefined;
            }
        } else if (this.assignmentService.getDraggedMode() === 'MOVE') {
            if (!this.canAssignContent()) {
                return 'Impossible de déplacer cette assignation ici';
            }
        }
    }

    checkAssignContentToUser(index: number) {
        const draggedElement = JSON.parse(
            JSON.stringify(this.assignmentService.getDraggedElement())
        );
        const draggedMode = JSON.parse(JSON.stringify(this.assignmentService.getDraggedMode()));

        if (index === undefined && !this.canAssignContent()) {
            return;
        }
        if (index !== undefined && !this.canAssignContentInParent()) {
            return;
        }
        if (draggedMode === 'COPY') {
            if (draggedElement.hasprice) {
                this.subscriptions.add(
                    this.dialogService.openSelectFIFCDialog().subscribe((FI: boolean) => {
                        if (index === undefined) {
                            this.assignContentToUser(
                                draggedElement,
                                this.assignment,
                                undefined,
                                FI
                            );
                        } else {
                            this.assignContentToUser(
                                draggedElement,
                                this.parent,
                                this.assignmentService.calculatePosition(this.parent, index),
                                FI
                            );
                        }
                    })
                );
            } else {
                if (index === undefined) {
                    this.assignContentToUser(draggedElement, this.assignment);
                } else {
                    this.assignContentToUser(
                        draggedElement,
                        this.parent,
                        this.assignmentService.calculatePosition(this.parent, index),
                        undefined
                    );
                }
            }
        } else {
            const position = this.assignmentService.calculatePosition(
                this.parent,
                index,
                draggedElement
            );
            if (position) {
                this.subscriptions.add(
                    this.assignmentService
                        .moveUserAssignment(draggedElement, position)
                        .subscribe(() => {
                            this.flashMessageService.flash(
                                `Le contenu <b>${draggedElement.title}</b> a été déplacé`
                            );
                            if (!this.parent.assignmentId) {
                                this.childrenMoved.emit({
                                    assignmentId: draggedElement.assignmentId,
                                    position: position
                                });
                            } else {
                                this.parent.children.map((assignment: Assignment) => {
                                    if (assignment.assignmentId === draggedElement.assignmentId) {
                                        assignment.position = position;
                                    }
                                    return assignment;
                                });
                            }
                            this.assignmentService.clearDraggedElement();
                            this.assignmentService.clearDraggedMode();
                        })
                );
            }
        }
    }

    assignContentToUser(element: Assignment, parent: Assignment, position?: number, FI?: boolean) {
        this.subscriptions.add(
            this.assignmentService
                .assignContentToUser(element, this.user, position, parent, FI)
                .subscribe((assignment: any) => {
                    this.dialogService
                        .openAddDisponibility(assignment.assignment)
                        .subscribe((data: any) => {
                            if (data) {
                                this.flashMessageService.flash(
                                    'La restriction calendaire a été ajoutée'
                                );
                                assignment.assignment.timestart = data.timestart;
                                assignment.assignment.timeend = data.timeend;
                                this.refreshDisponibility();
                            }
                            if (!parent || !parent.assignmentId) {
                                this.childrenAssigned.emit(assignment.assignment);
                            } else {
                                if (position) {
                                    this.parent.children.push(assignment.assignment);
                                } else {
                                    if (this.assignment.children) {
                                        this.assignment.children.push(assignment.assignment);
                                    } else {
                                        this.assignment.children = [assignment.assignment];
                                    }
                                }
                            }
                        });
                    this.flashMessageService.flash(
                        `Le contenu <b>${
                            element.title
                        }</b> a été assigné à l'utilisateur <b>${this.user.lastname.toUpperCase()} ${
                            this.user.firstname
                        }</b>`
                    );
                    this.assignmentService.clearDraggedElement();
                    this.assignmentService.clearDraggedMode();
                })
        );
    }

    getSortedChildren() {
        if (this.assignment.children) {
            return this.assignment.children.sort((a: Assignment, b: Assignment) => {
                if (a.position < b.position) {
                    return -1;
                }
                if (a.position > b.position) {
                    return 1;
                }
                return 0;
            });
        }
    }

    getScoreTooltip() {
        let string = '';
        if (this.assignment.previousAttempts) {
            this.assignment.previousAttempts.map((attempt, index) => {
                if (attempt === Math.max(...this.assignment.previousAttempts)) {
                    string += `Tentative ${index + 1} : <b>${attempt}/100</b> <br>`;
                } else {
                    string += `Tentative ${index + 1} : ${attempt}/100 <br>`;
                }
            });
        }
        return string;
    }

    showNotationButton() {
        return this.assignment.type === 'presentiel' && !this.notePanelOpened;
    }

    toggleNotePanel() {
        this.notePanelOpened = !this.notePanelOpened;
    }

    postNote(passed: boolean) {
        this.subscriptions.add(
            this.userService
                .noteContent(this.user.id, this.assignment.id, this.assignment.note, passed)
                .subscribe(() => {
                    this.flashMessageService.flash(
                        'Le contenu <strong>' + this.assignment.title + '</strong> a été noté'
                    );
                    this.assignment.tracking.status = passed ? 'passed' : 'failed';
                    this.assignment.tracking.score = this.assignment.note;
                    this.notePanelOpened = false;
                })
        );
    }

    hasGuide() {
        return !!this.assignment.guide;
    }

    viewGuide($event) {
        $event.stopImmediatePropagation();
        if (this.assignment.guide) {
            const popup = window.open(this.assignment.guide);
            if (!popup) {
                const warningTitle = "Impossible d'ouvrir la page";
                const warningBody =
                    "Vous utilisez un bloqueur de pop-ups qui vous empêche d'ouvrir un nouvel onglet. <br >Veuillez modifier les paramètres de votre navigateur pour autoriser les pop-ups pour le site : <br><strong>easi-training.fr</strong>";
                this.dialogService.openWarning(warningBody, warningTitle);
            }
        }
    }

    openAddDisponibilityDialog($event) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.dialogService.openAddDisponibility(this.assignment).subscribe((data: any) => {
                if (data) {
                    this.flashMessageService.flash('La restriction calendaire a été ajoutée');
                    this.assignment.timestart = data.timestart;
                    this.assignment.timeend = data.timeend;
                    this.refreshDisponibility();
                }
            })
        );
    }

    deleteAssignment($event) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.dialogService
                .openConfirmationDialog(
                    "Supprimer l'assignation",
                    `Êtes-vous sûr de vouloir supprimer l\'assignation du contenu "${this.assignment.title}"`
                )
                .subscribe((data) => {
                    if (data) {
                        this.subscriptions.add(
                            this.userService
                                .deleteUserAssignments(this.assignment.assignmentId)
                                .subscribe(() => {
                                    this.assignmentDeleted.emit(this.assignment);
                                    this.flashMessageService.flash(
                                        "L'assignation a été supprimée."
                                    );
                                })
                        );
                    }
                })
        );
    }

    canHideAssignment() {
        if (this.assignment.hasprice) {
            return (
                this.loginService.getUser().roles.nationalAdmin ||
                this.loginService.getUser().roles.localAdmin ||
                this.loginService.getUser().additionalRoles.contentManager
            );
        } else {
            return true;
        }
    }

    hideAssignment($event) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.userService.hideUserAssignment(this.assignment.assignmentId).subscribe(() => {
                this.assignment.status = 'hidden';
                this.assignmentStatusChanged.emit(this.assignment);
                this.flashMessageService.flash("L'assignation a été désassignée.");
            })
        );
    }

    showAssignment($event) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.userService.showUserAssignment(this.assignment.assignmentId).subscribe(() => {
                this.assignment.status = 'visible';
                this.assignmentStatusChanged.emit(this.assignment);
                this.flashMessageService.flash("L'assignation a été réassignée.");
            })
        );
    }

    removeAssignment(assignment: Assignment) {
        for (const i in this.assignment.children) {
            if (this.assignment.children[i].assignmentId === assignment.assignmentId) {
                this.assignment.children.splice(+i, 1);
            }
        }
    }

    openContentDialog($event) {
        $event.stopImmediatePropagation();
        this.dialogService.openContentDetails(this.assignment.id);
    }

    enabledValidationButton() {
        return (
            this.assignment.forceStatus &&
            this.assignment.tracking.forcedStatus === null &&
            (this.getIso() > 0 || this.assignment.level === null)
        );
    }

    validateAssignment($event) {
        $event.stopImmediatePropagation();
        if (this.assignment.level && !this.assignment.tracking.forcedStatus) {
            const dialogTitle = 'Forcer la validation';
            const dialogBody =
                "Par défaut le calcul du statut des assemblages est automatique.<br />Si vous décidez de forcer la validation ou l'invalidation, l'automatisme sera définitivement désactivé pour cet assemblage.<br />Êtes-vous sûr de vouloir forcer le changement de statut de cet élément ?";
            this.subscriptions.add(
                this.dialogService
                    .openConfirmationDialog(dialogTitle, dialogBody)
                    .subscribe((data: boolean) => {
                        if (data) {
                            this.subscriptions.add(
                                this.userService
                                    .validateContentForUser(this.user.id, this.assignment.id)
                                    .subscribe(() => {
                                        this.refreshTracking();
                                        this.flashMessageService.flash(
                                            'Le contenu <b>' +
                                                this.assignment.title +
                                                '</b> a été validé'
                                        );
                                    })
                            );
                        }
                    })
            );
        } else {
            this.subscriptions.add(
                this.userService
                    .validateContentForUser(this.user.id, this.assignment.id)
                    .subscribe(() => {
                        this.refreshTracking();
                        this.flashMessageService.flash(
                            'Le contenu <b>' + this.assignment.title + '</b> a été validé'
                        );
                    })
            );
        }
    }

    enabledInvalidationButton() {
        return (
            this.assignment.forceStatus &&
            this.assignment.tracking.forcedStatus !== null &&
            (this.getIso() > 0 || this.assignment.level === null)
        );
    }

    getAssignmentDate() {
        return (
            'Contenu assigné le ' + new Date(this.assignment.assignmentDate).toLocaleDateString()
        );
    }

    invalidateAssignment($event) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.userService
                .unvalidateContentForUser(this.user.id, this.assignment.id)
                .subscribe(() => {
                    this.refreshTracking();
                    this.flashMessageService.flash(
                        'Le contenu <b>' + this.assignment.title + '</b> a été invalidé'
                    );
                })
        );
    }

    refreshTracking() {
        this.subscriptions.add(
            this.userService.getUserAssignments(this.user.id).subscribe((success: any) => {
                this.assignmentService.emitRefreshTracking(success.tree);
            })
        );
    }

    updateTrackingRecursive(assignments) {
        assignments.map((assignment: Assignment) => {
            if (assignment.assignmentId === this.assignment.assignmentId) {
                this.assignment.tracking = assignment.tracking;
                if (assignment.correction_file) {
                    this.assignment.correction_file = assignment.correction_file;
                }
            }
            if (assignment.children) {
                this.updateTrackingRecursive(assignment.children);
            }
        });
    }

    refreshDisponibility() {
        this.subscriptions.add(
            this.userService.getUserAssignments(this.user.id).subscribe((success: any) => {
                this.assignmentService.emitRefreshDisponibility(success.tree);
            })
        );
    }

    updateDisponibilityRecursive(assignments) {
        assignments.map((assignment: Assignment) => {
            if (assignment.assignmentId === this.assignment.assignmentId) {
                this.assignment.timestart = assignment.timestart;
                this.assignment.timeend = assignment.timeend;
                if (this.assignment && this.parent) {
                    if (!this.assignment.timestart && this.parent.timestart) {
                        this.assignment.timestart = this.parent.timestart;
                        if (!this.assignment.timeend) {
                            this.heritedDisponibility = true;
                        }
                    }
                    if (!this.assignment.timeend && this.parent.timeend) {
                        this.assignment.timeend = this.parent.timeend;
                        if (!this.assignment.timestart) {
                            this.heritedDisponibility = true;
                        }
                    }
                }
            }
            if (assignment.children) {
                this.updateDisponibilityRecursive(assignment.children);
            }
        });
    }

    enabledResetTrackingButton() {
        return (
            (this.assignment.level === undefined || this.assignment.level === null) &&
            this.assignment.mediaTemplate !== 'machine' &&
            ['quiz', 'scorm'].indexOf(this.assignment.type) > -1 &&
            this.assignment.tracking.forcedStatus === null &&
            this.assignment.tracking.status !== 'not attempted'
        );
    }

    resetTracking($event) {
        $event.stopImmediatePropagation();
        let message: string;
        if (this.assignment.type === 'quiz') {
            message =
                "Vous allez proposer une nouvelle tentative :<br><br>Le statut de l'activité et la copie de l'apprenant seront réinitialisés.<br>Les résultats des anciennes tentatives seront conservés et visibles dans le rapport de test.<br><br><b>Continuer</b> ?";
        } else {
            message =
                "Vous allez proposer une nouvelle tentative :<br><br>Le statut de l'activité sera réinitialisé.<br>Le score de la tentative précédente ne sera pas conservée.<br>Seul le nombre de tentatives sera mis à jour dans le rapport de l'apprenant (.csv)<br><br><b>Continuer</b> ?";
        }
        this.dialogService
            .openConfirmationDialog('Proposer une nouvelle tentative', message)
            .subscribe((data: boolean) => {
                if (data) {
                    this.subscriptions.add(
                        this.userService
                            .removeTracking(this.user.id, this.assignment.id)
                            .subscribe(() => {
                                this.flashMessageService.flash(
                                    'Le contenu <b>' +
                                        this.assignment.title +
                                        '</b> a été réinitialisé'
                                );
                                this.assignment.tracking.status = 'not attempted';
                                this.assignment.tracking.score = undefined;
                            })
                    );
                }
            });
    }

    resetTrackingDevoir($event) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.dialogService
                .openConfirmationDialog(
                    'Proposer une nouveau depot',
                    "Vous allez proposer une nouvelle tentative :<br><br>Le statut de l'activité sera réinitialisé<br>Le devoir rendu précédemment ainsi que la note seront supprimés définitivement<br><br><b>Continuer</b> ?"
                )
                .subscribe((confirmation: boolean) => {
                    if (confirmation) {
                        this.subscriptions.add(
                            this.userService
                                .removeTracking(this.user.id, this.assignment.id)
                                .subscribe(() => {
                                    this.flashMessageService.flash(
                                        'Le contenu <b>' +
                                            this.assignment.title +
                                            '</b> a été réinitialisé'
                                    );
                                    this.assignment.tracking.status = 'not attempted';
                                    this.assignment.tracking.score = undefined;
                                })
                        );
                    }
                })
        );
    }

    getLaunchButtonTooltip() {
        if (this.assignment.type === 'devoir') {
            return "Voir l'énoncé";
        } else {
            return 'Voir le contenu';
        }
    }

    openActivity() {
        this.libraryService.openActivity(this.assignment);
    }

    markAsReferent($event) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.userService.markAsReferent(this.assignment.assignmentId).subscribe(() => {
                this.flashMessageService.flash(
                    "Vous êtes devenu référent de l'assignation <strong>" +
                        this.assignment.title +
                        '</strong>'
                );
                this.assignment.referents.push(this.loginService.getUser().id);
            })
        );
    }

    unmarkAsReferent($event) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.userService.unmarkAsReferent(this.assignment.assignmentId).subscribe(() => {
                this.flashMessageService.flash(
                    "Vous n'êtes plus référent de l'assignation <strong>" +
                        this.assignment.title +
                        '</strong>'
                );
                const index = this.assignment.referents.indexOf(this.loginService.getUser().id);
                this.assignment.referents.splice(index, 1);
            })
        );
    }

    removeDisponibility($event) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.userService
                .setDisponibilityToUserAssignment(this.assignment.assignmentId, null, null)
                .subscribe(() => {
                    this.assignment.timestart = undefined;
                    this.assignment.timeend = undefined;
                    this.refreshDisponibility();
                })
        );
    }

    isQuiz() {
        return this.assignment.type === 'quiz';
    }

    canStartReview() {
        return (
            this.assignment.type === 'quiz' &&
            ((this.assignment.tracking.attempt === 1 &&
                this.assignment.tracking.status !== 'opened') ||
                this.assignment.tracking.attempt > 1)
        );
    }

    startReview() {
        const popup = window.open(
            `${this.configService.getFrontEndPoint()}/quizPlayer/#/${
                this.assignment.id
            }?teacher=true&studentId=${this.user.id}`,
            '_blank'
        );
        if (!popup) {
            const warningTitle = "Impossible d'ouvrir la page";
            const warningBody =
                "Vous utilisez un bloqueur de pop-ups qui vous empêche d'ouvrir un nouvel onglet. <br >Veuillez modifier les paramètres de votre navigateur pour autoriser les pop-ups pour le site : <br><strong>easi-training.fr</strong>";
            this.dialogService.openWarning(warningBody, warningTitle);
        }
    }

    getReportingTooltip() {
        if (this.isActivity()) {
            return 'Rapport du test';
        } else {
            return "Rapport des tests de l'assemblage";
        }
    }

    openReportingUser($event) {
        $event.stopImmediatePropagation();
        if (this.isQuiz()) {
            window.open(
                this.configService.getReportingFrontEndpoint() +
                    '#/?studentId=' +
                    this.user.id +
                    '&select=test&id=' +
                    this.assignment.quizId +
                    '&name=' +
                    this.assignment.title,
                '_blank'
            );
        } else if (!this.isActivity()) {
            this.quizList = [];
            this.getQuizsContentFromAssignments(this.assignment.children);
            window.open(
                this.configService.getReportingFrontEndpoint() +
                    '#/?studentId=' +
                    this.user.id +
                    '&select=test&id=' +
                    this.quizList.join('|') +
                    '&name=' +
                    this.assignment.title,
                '_blank'
            );
        }
    }

    getQuizsContentFromAssignments(assignments) {
        let found: boolean;
        for (const i in assignments) {
            if (assignments[i].type === 'quiz') {
                found = false;
                for (const j in this.quizList) {
                    if (this.quizList[j] === assignments[i].quizId) {
                        found = true;
                    }
                }
                if (!found) {
                    this.quizList.push(assignments[i].quizId);
                }
            } else if (assignments[i].children) {
                this.getQuizsContentFromAssignments(assignments[i].children);
            }
        }
    }

    isDevoir() {
        return this.assignment.type === 'devoir';
    }

    isNotCompleted() {
        return (
            this.assignment.tracking.status === 'not attempted' ||
            this.assignment.tracking.status === 'opened'
        );
    }

    isCompleted() {
        return this.assignment.tracking.status === 'completed';
    }

    openUserTrackingDialog($event) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.dialogService
                .openUserTracking(this.assignment, this.user)
                .subscribe((data: any) => {
                    if (data) {
                        this.flashMessageService.flash('Les notes ont été enregistrés');
                        this.assignment.tracking.score = data[Object.keys(data)[0]];
                    }
                })
        );
    }

    remindWork($event: Event): void {
        const params = {
            enrolmentSource: 'individual',
            assignmentId: this.assignment.assignmentId
        };
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.assignmentService.remindAssignment(params).subscribe((data: boolean) => {
                this.flashMessageService.flash('Email envoyé');
            })
        );
    }

    canViewScore() {
        if (
            this.assignment.tracking.score === undefined ||
            this.assignment.tracking.score === null
        ) {
            return false;
        }
        if (
            this.assignment.tracking.status === 'opened' ||
            this.assignment.tracking.status === 'not attempted'
        ) {
            return false;
        }
        if (
            this.assignment.type === 'quiz' &&
            (!this.assignment.canViewScore || this.assignment.tracking.attempt < 1)
        ) {
            return false;
        }

        if (this.assignment.type === 'partenariat') {
            return true;
        }

        switch (this.assignment.category) {
            case 'positionnement':
            case 'evaluation':
            case 'tp':
            case 'presentiel':
            case 'devoir':
                return true;

            case 'preparation':
            case 'ressource':
            case 'travail':
            case 'corrige':
            case 'guide':
            case 'url':
                return false;

            default:
                return false;
        }
    }

    showDuration() {
        if (
            this.getDuration() &&
            (this.assignment.level === 'module' ||
                this.assignment.level === 'sequence' ||
                !this.assignment.level)
        ) {
            return true;
        }
        return false;
    }

    isDisabled() {
        return this.assignment.status === 'hidden';
    }

    /**
     * Retourne duration formatté s'il existe, sinon content_duration formatté s'il existe, ou false;
     */
    getDuration(): string | false {
        if (!!this.assignment.duration && this.assignment.duration !== '00:00') {
            const timeArray = this.assignment.duration.split(':');
            const time = {
                hours: +timeArray[0],
                minutes: +timeArray[1]
            };
            if (!this.isIncompleteDuration()) {
                return (
                    'Durée estimée : ' +
                    (time.hours ? time.hours + 'h' : '') +
                    (time.minutes ? time.minutes + 'mn' : '')
                );
            } else {
                return (
                    'Durée estimée : ' +
                    (time.hours ? time.hours + 'h' : '') +
                    (time.minutes ? time.minutes + 'mn' : '') +
                    "(durée d'activité(s) manquante(s) dans l'assemblage)"
                );
            }
        } else if (
            this.assignment.content_duration &&
            this.assignment.content_duration !== '00:00'
        ) {
            const timeArray = this.assignment.content_duration.split(':');
            const time = {
                hours: +timeArray[0],
                minutes: +timeArray[1]
            };
            if (!this.isIncompleteDuration()) {
                return (
                    'Durée estimée : ' +
                    (time.hours ? time.hours + 'h' : '') +
                    (time.minutes ? time.minutes + 'mn' : '')
                );
            } else {
                return (
                    'Durée estimée : ' +
                    (time.hours ? time.hours + 'h' : '') +
                    (time.minutes ? time.minutes + 'mn' : '') +
                    "(durée d'activité(s) manquante(s) dans l'assemblage)"
                );
            }
        }
        return false;
    }

    isIncompleteDuration() {
        if (this.assignment.level === 'module' || this.assignment.level === 'sequence') {
            return this.checkIncompleteDurationRecursive(this.assignment);
        }
        return false;
    }

    checkIncompleteDurationRecursive(entry) {
        if (
            (!entry.duration || entry.duration === '00:00') &&
            (!entry.content_duration || entry.content_duration === '00:00')
        ) {
            return true;
        }
        for (const i in entry.children) {
            if (entry.children[i]) {
                const checkChildrenIncompleteDuration = this.checkIncompleteDurationRecursive(
                    entry.children[i]
                );
                if (checkChildrenIncompleteDuration) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return false;
    }

    isAssignationPersonnalized() {
        return this.assignment.active_users < this.assignment.users;
    }

    getPersonnalizationCount() {
        return `${this.assignment.active_users}/ ${this.assignment.users}`;
    }

    getIso() {
        return this.assignment.isIso;
    }

    getIsoWarning() {
        if (this.assignment.isIso === 0) {
            return 'Cet assemblage est assigné plusieurs fois à cet apprenant <br>et sa composition ou les restrictions de sa composition varie.<br> Le calcul de la complétion prend donc en compte l’ensemble<br> de tous ses enfants, où qu’ils soient situés parmi les assignations.';
        } else if (this.assignment.isIso === -1) {
            return 'Cet assemblage contient des enfants qui sont assignés plusieurs fois à cet apprenant <br>et leur composition ou les restrictions de leur composition varie.';
        }
    }

    showDisponibilityIcon() {
        return !!this.assignment.timestart;
    }

    getAddDisponibilityTooltip() {
        if (this.showDisponibilityIcon()) {
            return 'Modifier la restriction calendaire';
        } else {
            return 'Ajouter une restriction calendaire';
        }
    }

    showWarningDisponibility() {
        return (
            this.getDisponibilityClass() === 'grey' &&
            (this.assignment.tracking.status === 'not attempted' ||
                this.assignment.tracking.status === 'opened')
        );
    }

    getDisponibilityClass = function () {
        const now = new Date();
        const start = new Date(this.assignment.timestart);
        let end;
        if (this.assignment.timeend !== null) {
            end = new Date(this.assignment.timeend);
        }

        if (start.getTime() > now.getTime()) {
            return 'orange';
        }

        if (end && end.getTime() < now.getTime()) {
            return 'grey';
        }
        return 'green';
    };

    getDisponibilityTooltip() {
        let tmp = this.datesPipe.fromStartToEndFormatting({
            start: this.assignment.timestart,
            end: this.assignment.timeend
        });
        if (this.showWarningDisponibility()) {
            tmp += ", l'apprenant n'a pas terminé dans le délai";
        }
        return tmp;
    }

    getTrackingStatusIconStyle() {
        if (this.assignment.updateinprogress) {
            return 'icon-Voirplus color_gris1_foreground';
        }

        const status = this.assignment.tracking.forcedStatus
            ? this.assignment.tracking.forcedStatus
            : this.assignment.tracking.status;

        switch (status) {
            case 'not attempted':
                return 'icon-select';
            case 'opened':
                return 'icon-Progression40';
            case 'completed':
                return 'icon-online';
            case 'failed':
                return 'icon-Fermerdetails';
            case 'passed':
                return 'icon-Valide';
            default:
                return 'icon-select';
        }
    }

    getTextColorStyle() {
        if (this.assignment.tracking.forcedStatus) {
            return 'orange';
        }
        switch (this.assignment.tracking.status) {
            case 'completed': {
                return 'blue';
            }
            case 'failed': {
                return 'red';
            }
            case 'passed': {
                return 'green';
            }
            default: {
                return '';
            }
        }
    }

    getTrackingStatusText() {
        let text = '';
        if (this.assignment.updateinprogress) {
            return 'Assignation en cours...';
        }

        const status = this.assignment.tracking.forcedStatus
            ? this.assignment.tracking.forcedStatus
            : this.assignment.tracking.status;

        switch (status) {
            case 'not attempted': {
                text += 'Nouveau';
                break;
            }
            case 'opened': {
                text += 'En cours';
                break;
            }
            case 'completed': {
                text += 'Terminé';
                break;
            }
            case 'failed': {
                text += 'Non réussi';
                break;
            }
            case 'passed': {
                text += 'Réussi';
                break;
            }
            default: {
                text += 'Nouveau';
                break;
            }
        }
        text += this.assignment.tracking.forcedStatusAuthorName
            ? ' (validé par ' + this.assignment.tracking.forcedStatusAuthorName + ')'
            : '';
        return text;
    }

    getReferentAvatar() {
        if (this.assignment.referents.length === 1) {
            const currentReferent = this.referents.filter((referent: User) => {
                return referent.id === this.assignment.referents[0];
            })[0];
            if (currentReferent && currentReferent.avatar) {
                return currentReferent.avatar;
            }
            return false;
        }
        return false;
    }

    getReferentTooltip() {
        if (this.assignment.referents.length === 0) {
            return 'Aucun formateur référent';
        }
        let text = 'Formateur(s) référent(s) :<br>';
        for (const i in this.assignment.referents) {
            if (this.assignment.referents) {
                const currentReferent = this.referents.filter((referent: User) => {
                    return referent.id === this.assignment.referents[i];
                })[0];
                if (currentReferent) {
                    text +=
                        '<i>' +
                        currentReferent.lastname.toUpperCase() +
                        ' ' +
                        currentReferent.firstname;
                    if (this.assignment.referents[i] === this.loginService.getUser().id) {
                        text += ' (vous)';
                    }
                    text += '</i><br>';
                }
            }
        }
        return text.substring(0, text.length - 4);
    }

    isReferent() {
        return this.assignment.referents.indexOf(this.loginService.getUser().id) > -1;
    }

    hasPrice() {
        return this.assignment.hasprice !== 0 && this.assignment.hasprice !== null;
    }

    isOrdered() {
        return this.assignment.ordered;
    }

    isActivity(): boolean {
        return !this.assignment.level;
    }

    toggleIcon() {
        this.opened = !this.opened;
    }

    getAssignmentTooltip() {
        const titre = `Titre : ${this.assignment.title}`;
        const id = this.assignment.id ? `<br/>ID : ${this.assignment.id}` : '';
        const duration = this.showDuration() ? `<br/>${this.getDuration()}` : '';
        return titre + id + duration;
    }

    selectAssignment($event: Event) {
        $event.stopPropagation();
        if (!this.isGroupAssignment()) {
            if (!this.multiselectService.isAssignmentSelected(this.assignment)) {
                this.multiselectService.addAssignmentToSelection(this.assignment);
            } else {
                this.multiselectService.removeAssignmentFromSelection(this.assignment);
            }
        }
    }

    showCertificate() {
        return (
            !this.isActivity() &&
            this.assignment.hasCertificate &&
            ['completed', 'passed'].indexOf(this.assignment.tracking.status) > -1
        );
    }

    getCertificate($event: Event) {
        $event.stopPropagation();
        this.assignmentService.getCertificate(this.assignment, this.user).subscribe((data) => {
            const blob = new Blob([data.body], {
                type: 'application/pdf'
            });
            FileSaver.saveAs(
                blob,
                `${this.user.lastname.toUpperCase()}_${this.user.firstname}_${
                    this.assignment.title
                }.pdf`
            );
        });
    }
}
