<div class="creation-space" *ngIf="!isLoading() && drafts">
    <div class="creation-space-header">
        <div class="search-container">
            <input
                class="library-search-field"
                [(ngModel)]="searchTerm"
                placeholder="Chercher..."
                (keyup.enter)="applySearch()"
            />
            <span class="icon-search" (click)="applySearch()"></span>
            <span class="icon-Fermerdetails" *ngIf="searchTerm" (click)="cancelSearch()"></span>
        </div>
        <div class="blank"></div>
        <div class="fold-all" (click)="emitFoldAll()" [tooltip]="'Revenir à la vue par défaut'">
            Vue par défaut
        </div>
    </div>
    <div class="separator"></div>
    <mat-expansion-panel class="content-container" [expanded]="expandedActivity">
        <mat-expansion-panel-header
            class="legend"
            [collapsedHeight]="'30px'"
            [expandedHeight]="'30px'"
            >Activités en cours de création ({{ getActivityCount() }})</mat-expansion-panel-header
        >
        <app-activity-creation
            *ngFor="let activity of drafts.activities | creationSpaceSearch: search"
            [activity]="activity"
            [search]="search"
            (clonedActivity)="addActivity($event)"
            (deletedActivity)="removeActivity($event)"
        ></app-activity-creation>
    </mat-expansion-panel>
    <div class="separator"></div>
    <mat-expansion-panel class="content-container" [expanded]="expandedAssembly">
        <mat-expansion-panel-header
            class="legend"
            [collapsedHeight]="'30px'"
            [expandedHeight]="'30px'"
            >Assemblages en cours de création ({{ getAssemblyCount() }})</mat-expansion-panel-header
        >
        <app-assembly-creation
            *ngFor="let draft of drafts.assemblies; index as i"
            [draft]="draft"
            [index]="i"
            [search]="search"
        ></app-assembly-creation>
    </mat-expansion-panel>
    <div class="separator"></div>
    <mat-expansion-panel class="content-container" [expanded]="expandedUpdate">
        <mat-expansion-panel-header
            class="legend"
            [collapsedHeight]="'30px'"
            [expandedHeight]="'30px'"
            >Contenus en cours de modification ({{ getUpdateCount() }})</mat-expansion-panel-header
        >
        <app-content-update
            *ngFor="let draft of drafts.update; index as i"
            [draft]="draft"
            [index]="i"
            [search]="search"
            (clonedUpdate)="cloneUpdate($event)"
            (deletedUpdate)="removeUpdate($event)"
        ></app-content-update>
    </mat-expansion-panel>
    <div class="create-content" (click)="openContentSelection()" [tooltip]="'Créer un contenu'">
        <div class="icon-plus"></div>
    </div>
</div>
<div class="creation-space" *ngIf="isLoading()">
    <app-loading></app-loading>
</div>
