import {
    Component,
    Input,
    OnInit,
    OnChanges,
    Output,
    EventEmitter,
    SimpleChanges
} from '@angular/core';
import { Subscription } from 'rxjs';

import { AssignmentService } from '../../../../services/assignment.service';
import { ConfigService } from '../../../../services/config.service';
import { DialogService } from '../../../../services/dialog.service';
import { FavoriteService } from '../../../../services/favorite.service';
import { FlashMessageService } from '../../../../services/flash-message.service';
import { LibraryService } from '../../../../services/library.service';
import { LoginService } from '../../../../services/login.service';

import { Content } from '../../../../structures/content';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-assembly-creation',
    templateUrl: './assembly-creation.component.html',
    styleUrls: ['./assembly-creation.component.scss']
})
export class AssemblyCreationComponent implements OnInit, OnChanges {
    @Input() draft: Content;
    @Output() draftChange = new EventEmitter<Content>();
    @Input() parent: Content;
    @Input() index: number;
    @Input() editOrderChildren: boolean;
    @Input() locked = false;
    @Input() search: string;

    initialDraft: Content;
    editOrder: boolean;
    subscriptions = new Subscription();
    opened: boolean;
    openedBySearch: boolean;

    constructor(
        private assignmentService: AssignmentService,
        private configService: ConfigService,
        private dialogService: DialogService,
        private favoriteService: FavoriteService,
        private flashMessageService: FlashMessageService,
        private libraryService: LibraryService,
        private loginService: LoginService
    ) {}

    ngOnInit() {
        this.editOrder = false;
        if (!this.isRootContent()) {
            this.draft.parentid = this.parent.id;
        } else {
            this.draft.parentid = undefined;
        }
        this.subscriptions.add(
            this.libraryService.deletedDraft.subscribe((data: Content) => {
                const index = this.draft.children.findIndex(
                    (draft: Content) => draft.id === data.id
                );
                if (index !== -1) {
                    this.draft.children.splice(index, 1);
                }
            })
        );
        this.subscriptions.add(
            this.libraryService.deletedChild.subscribe((data: any) => {
                const { child: childToDelete, parent: parentToClean } = data;
                if (parentToClean && this.draft.id === parentToClean.id) {
                    const index = this.draft.children
                        ? this.draft.children.findIndex(
                              (content) => content.id === childToDelete.id
                          )
                        : -1;
                    if (index !== -1) {
                        const removedChildStatus = this.draft.children[index].status;
                        this.draft.children.splice(index, 1);
                        if (this.draft.children.length <= 1 && this.draft.ordered === true) {
                            this.draft.ordered = false;
                            if (this.draft.children) {
                                this.draft.children.forEach((childElement) => {
                                    delete childElement.orderNumber;
                                });
                            }
                        }
                        this.subscriptions.add(
                            this.libraryService
                                .updateAssembly(this.draft, undefined)
                                .subscribe(() => {
                                    if (removedChildStatus === 'approved') {
                                        this.flashMessageService.flash(
                                            "Le contenu a été retiré de l'assemblage"
                                        );
                                    } else {
                                        this.flashMessageService.flash(
                                            `Le contenu a été sorti de l'assemblage et placé à la racine de vos ASSEMBLAGES EN COURS DE CRÉATION`
                                        );
                                    }
                                    if (childToDelete.status === 'draft' && !childToDelete.moved) {
                                        this.libraryService.emitAddedDraft(childToDelete);
                                    }
                                })
                        );
                    }
                }
            })
        );
        this.subscriptions.add(
            this.libraryService.foldAll.subscribe(() => {
                this.opened = false;
                this.openedBySearch = false;
            })
        );
        if (this.draft.status === 'approved') {
            this.locked = true;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.editOrderChildren && changes.editOrderChildren.currentValue) {
            if (!this.draft.orderNumber) {
                this.draft.orderNumber = this.index + 1;
            }
        }
        if (changes.search) {
            if (this.childrenMatchSearch(this.draft.children)) {
                this.openedBySearch = true;
            } else {
                this.openedBySearch = false;
            }
        }
    }

    isRootContent() {
        return !this.parent;
    }

    isFirstElement(index: number): boolean {
        return index === 0;
    }

    isLocked() {
        return this.locked;
    }

    isActivity(): boolean {
        return !this.draft.level;
    }

    displayElement(content: Content) {
        if (!this.search) {
            return true;
        } else {
            return this.matchSearch(content) || this.childrenMatchSearch(content.children);
        }
    }

    childrenMatchSearch(contents: Array<Content>) {
        if (!this.search) {
            return false;
        }
        for (const content of contents) {
            if (
                content.title.toLowerCase().includes(this.search.toLowerCase()) ||
                content.description.toLowerCase().includes(this.search.toLowerCase()) ||
                content.keywords.findIndex(
                    (keyword) => keyword.toLowerCase() === this.search.toLowerCase()
                ) !== -1
            ) {
                return true;
            } else {
                if (this.childrenMatchSearch(content.children)) {
                    return true;
                }
            }
        }
        return false;
    }

    matchSearch(content: Content): boolean {
        if (!this.search) {
            return false;
        }
        if (
            content.title.toLowerCase().includes(this.search.toLowerCase()) ||
            content.description.toLowerCase().includes(this.search.toLowerCase()) ||
            content.keywords.findIndex(
                (keyword) => keyword.toLowerCase() === this.search.toLowerCase()
            ) !== -1
        ) {
            return true;
        } else {
            return false;
        }
    }

    getIcon(): string {
        if (this.draft.status === 'approved') {
            return this.libraryService.getIcon(this.draft);
        } else {
            return this.libraryService.getDraftIcon(this.draft);
        }
    }

    getDraftTooltip(): string {
        return `<strong>Titre : </strong>${this.draft.title}</br>
        ${this.draft.id ? '<strong>ID : </strong>' : ''}${this.draft.id || ''}${
            this.draft.ucode ? '<strong> Code : </strong>' : ''
        }${this.draft.ucode || ''}</br>
        ${this.draft.description ? '<strong>Description : </strong>' : ''}${
            this.draft.description || ''
        }</br>
        ${this.getDuration()}`;
    }

    getDuration() {
        if (
            this.draft.duration !== '00:00' &&
            !!this.draft.duration &&
            (this.draft.level === 'module' || this.draft.level === 'sequence' || !this.draft.level)
        ) {
            const timeArray = this.draft.duration.split(':');
            const time = {
                hours: +timeArray[0],
                minutes: +timeArray[1]
            };
            if (!this.isIncompleteDuration()) {
                return (
                    'Durée estimée : ' +
                    (time.hours ? time.hours + 'h' : '') +
                    (time.minutes ? time.minutes + 'mn' : '')
                );
            } else {
                return (
                    'Durée estimée : ' +
                    (time.hours ? time.hours + 'h' : '') +
                    (time.minutes ? time.minutes + 'mn' : '') +
                    "(durée d'activité(s) manquante(s) dans l'assemblage)"
                );
            }
        }
        return '';
    }

    isIncompleteDuration() {
        if (this.draft.level === 'module' || this.draft.level === 'sequence') {
            return this.checkIncompleteDurationRecursive(this.draft);
        }
        return false;
    }

    checkIncompleteDurationRecursive(entry) {
        if (
            (!entry.duration || entry.duration === '00:00') &&
            (!entry.content_duration || entry.content_duration === '00:00')
        ) {
            return true;
        }
        for (const i in entry.children) {
            if (entry.children[i]) {
                const checkChildrenIncompleteDuration = this.checkIncompleteDurationRecursive(
                    entry.children[i]
                );
                if (checkChildrenIncompleteDuration) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return false;
    }

    increaseOrder($event: any) {
        $event.stopImmediatePropagation();
        this.draft.orderNumber++;
    }

    decreaseOrder($event: any) {
        $event.stopImmediatePropagation();
        if (this.draft.orderNumber > 1) {
            this.draft.orderNumber--;
        }
    }

    getFicheTooltip(): string {
        if (!this.isLocked()) {
            return 'Editer la fiche';
        } else {
            return 'Voir la fiche';
        }
    }

    showMediaIcon(): boolean {
        return this.draft.type === 'media' || this.draft.type === 'quiz';
    }

    showOrdoOnIcon(): boolean {
        return (
            !this.isLocked() &&
            !this.isActivity() &&
            !this.editOrder &&
            this.draft.children &&
            this.draft.children.length > 1
        );
    }

    showOrdoOffIcon(): boolean {
        return !this.isLocked() && this.draft.ordered && !this.isActivity() && !this.editOrder;
    }

    viewFiche($event: any) {
        $event.stopImmediatePropagation();
        if (!this.isLocked()) {
            this.subscriptions.add(
                this.libraryService.getContent(this.draft.id).subscribe((content: Content) => {
                    this.dialogService.openContentCreation(content).subscribe((data: Content) => {
                        if (data !== undefined) {
                            this.draft = {
                                ...this.draft,
                                ...data
                            };
                        }
                        this.draftChange.emit(this.draft);
                    });
                })
            );
        } else {
            this.dialogService.openContentDetails(this.draft.id);
        }
    }

    viewGuide($event: any) {
        $event.stopImmediatePropagation();
        if (this.draft.guide) {
            const popup = window.open(this.draft.guide);
            if (!popup) {
                const warningTitle = "Impossible d'ouvrir la page";
                const warningBody =
                    "Vous utilisez un bloqueur de pop-ups qui vous empêche d'ouvrir un nouvel onglet. <br >Veuillez modifier les paramètres de votre navigateur pour autoriser les pop-ups pour le site : <br><strong>easi-training.fr</strong>";
                this.dialogService.openWarning(warningBody, warningTitle);
            }
        }
    }

    goToMedia($event: any) {
        $event.stopImmediatePropagation();
        window.open(this.configService.getEasiMediaFrontEndPoint(), '_blank');
    }

    toggleOrdoOn($event: any) {
        $event.stopImmediatePropagation();
        this.initialDraft = JSON.parse(JSON.stringify(this.draft));
        this.opened = true;
        this.draft.ordered = true;
        this.editOrder = true;
    }

    toggleOrdoOff($event: any) {
        $event.stopImmediatePropagation();
        this.draft.ordered = false;
        if (!this.draft.children) {
            this.subscriptions.add(
                this.libraryService.getContent(this.draft.id).subscribe((data: any) => {
                    this.draft.children = data.children;
                    this.draft.children.map((child: Content) => {
                        delete child.orderNumber;
                        return child;
                    });
                    this.subscriptions.add(
                        this.libraryService.updateAssembly(this.draft, undefined).subscribe(() => {
                            this.editOrder = false;
                            this.flashMessageService.flash(
                                "L'ordonnancement du contenu a été supprimé"
                            );
                        })
                    );
                })
            );
        } else {
            this.draft.children.map((child: Content) => {
                delete child.orderNumber;
                return child;
            });
            this.subscriptions.add(
                this.libraryService.updateAssembly(this.draft, undefined).subscribe(() => {
                    this.editOrder = false;
                    this.flashMessageService.flash("L'ordonnancement du contenu a été supprimé");
                })
            );
        }
    }

    getOrdoTooltip() {
        if (!this.draft.ordered) {
            return 'Ordonner le contenu';
        } else {
            return "Modifier l'ordonnancement";
        }
    }

    cloneAssembly($event: any) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.dialogService
                .openAssemblyConfirmation(this.draft, 'copy')
                .subscribe((confirmed: boolean) => {
                    if (confirmed) {
                        this.subscriptions.add(
                            this.libraryService
                                .duplicateDraft(this.draft)
                                .subscribe((data: any) => {
                                    this.libraryService.emitAddedDraft(data);
                                    this.flashMessageService.flash(
                                        "Le contenu a été dupliqué dans l'espace de création"
                                    );
                                })
                        );
                    }
                })
        );
    }

    deleteAssembly($event: any) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.dialogService
                .openAssemblyConfirmation(this.draft, 'delete')
                .subscribe((confirmed: boolean) => {
                    if (confirmed) {
                        this.subscriptions.add(
                            this.libraryService.deleteDraft(this.draft).subscribe(() => {
                                this.libraryService.emitDeletedDraft(this.draft);
                                this.flashMessageService.flash('Le contenu a été supprimé');
                            })
                        );
                    }
                })
        );
    }

    publishAssembly($event: any) {
        $event.stopImmediatePropagation();
        if (this.draft.publishable) {
            this.subscriptions.add(
                this.dialogService
                    .openAssemblyConfirmation(this.draft, 'publish')
                    .subscribe((confirmed: boolean) => {
                        if (confirmed) {
                            this.subscriptions.add(
                                this.dialogService
                                    .openSelectPublication(this.draft)
                                    .subscribe((data: any) => {
                                        if (data) {
                                            this.draft.publicationMode = data.publicationMode;
                                            this.subscriptions.add(
                                                this.libraryService
                                                    .approveContent(this.draft, data.comment)
                                                    .subscribe(() => {
                                                        this.flashMessageService.flash(
                                                            'Le contenu a été publié dans la bibliothèque'
                                                        );
                                                        if (this.parent) {
                                                            this.locked = true;
                                                        } else {
                                                            this.libraryService.emitDeletedDraft(
                                                                this.draft
                                                            );
                                                        }
                                                        this.libraryService.emitRefreshLibrary();
                                                    })
                                            );
                                        }
                                    })
                            );
                        }
                    })
            );
        } else {
            this.dialogService
                .openContentCreation({ ...this.draft, with_publish_option: true })
                .subscribe((data: any) => {
                    console.log(data);

                    if (data) {
                        this.draft.publishable = true;
                        this.publishAssembly($event);
                    }
                });
        }
    }

    canAddChild() {
        return !this.isLocked() && this.draft.level !== 'sequence';
    }

    addChild($event: any) {
        $event.stopImmediatePropagation();
        const levelArray = ['parcours', 'bloc', 'competence', 'module', 'sequence'];
        const currentLevel = levelArray.indexOf(this.draft.level);
        const disabledLevel = levelArray.slice(0, currentLevel + 1);
        if (levelArray[currentLevel] === 'bloc' || levelArray[currentLevel] === 'parcours') {
            disabledLevel.push('sequence');
        }
        const position = this.assignmentService.calculatePosition(
            this.draft,
            this.draft.children.length
        );
        const content = {
            ...this.draft,
            parentid: this.draft.id,
            level: levelArray[currentLevel + 1],
            disabledLevel: disabledLevel,
            ordered: false,
            position
        };
        delete content.id;
        delete content.children;
        delete content.basedOn;

        this.subscriptions.add(
            this.dialogService.openContentCreation(content).subscribe((createdContent: Content) => {
                // rustine car le back ne le renvoie pas.
                if (!createdContent.position) {
                    createdContent.position = content.position;
                }
                this.draft.children.push(createdContent);
                this.opened = true;
            })
        );
    }

    canRemoveChild() {
        return !this.isRootContent() && this.parent.status !== 'approved';
    }

    getRemoveChildIcon() {
        return this.draft.status === 'approved'
            ? "Retirer de l'assemblage"
            : "Sortir de l'assemblage";
    }

    removeChild($event: any) {
        $event.stopImmediatePropagation();
        this.draft.moved = false;
        this.libraryService.emitDeletedChild(this.draft, this.parent);
        if (this.draft.status !== 'approved') {
            this.draft.parentid = undefined;
            this.draft.orderNumber = undefined;
        }
    }

    updateChild(child: Content) {
        const childIndex = this.draft.children.findIndex((ch) => ch.id === child.id);
        if (childIndex > -1) {
            this.draft.children[childIndex] = child;
        }
    }

    addToFavorites($event): void {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.favoriteService.addContentToFavorites(this.draft).subscribe((data: any) => {
                this.draft.favorite = true;
                this.flashMessageService.flash(
                    `Le contenu ${this.draft.title} a été ajouté à vos favoris`
                );
            })
        );
    }

    removeFromFavorites($event: any): void {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.favoriteService.removeContentFromFavorites(this.draft).subscribe((data: any) => {
                this.draft.favorite = false;
                this.flashMessageService.flash(
                    `Le contenu ${this.draft.title} a été retiré de vos favoris`
                );
            })
        );
    }

    openActivity($event): void {
        $event.stopImmediatePropagation();
        this.libraryService.openActivity(this.draft);
    }

    toggleIcon($event): void {
        $event.stopImmediatePropagation();
        this.opened = !this.opened;
    }
    getSortedChildren() {
        if (this.draft.children) {
            return this.draft.children.sort((a: Content, b: Content) => {
                if (a.position < b.position) {
                    return -1;
                }
                if (a.position > b.position) {
                    return 1;
                }
                return 0;
            });
        }
    }

    startDrag() {
        this.assignmentService.setDraggedElement(this.draft);
        this.assignmentService.setDraggedMode('MOVE');
    }

    endDrag() {
        this.assignmentService.clearDraggedElement();
        this.assignmentService.clearDraggedMode();
    }

    getForbiddenDropTooltipMessage(): string {
        if (this.assignmentService.getDraggedMode() === 'COPY') {
            if (!this.canDropContent()) {
                if (this.assignmentService.getDraggedElement().id === this.draft.id) {
                    return;
                }
                if (
                    this.assignmentService.getDraggedElement().hasprice === 2 &&
                    !this.loginService.getUser().roles.nationalAdmin
                ) {
                    return 'Vous ne pouvez pas utiliser les enfants de contenus payants dans vos créations';
                }
                if (this.locked) {
                    return 'Vous ne pouvez rien déposer sur un contenu issu de la bibliothèque';
                }
                switch (this.draft.category) {
                    case 'positionnement':
                    case 'preparation':
                    case 'ressource':
                    case 'travail':
                    case 'evaluation':
                    case 'corrige':
                    case 'tp':
                    case 'presentiel':
                    case 'guide':
                    case 'url':
                    case 'devoir':
                        return 'Vous ne pouvez rien déposer sur une activité';
                    default: {
                        switch (this.draft.level) {
                            case 'parcours':
                                return "Seuls les blocs, les compétences et les modules peuvent être déposés à la racine d'un parcours";
                            case 'bloc':
                                return "Seuls les compétences et les modules peuvent être déposés à la racine d'un bloc";
                            case 'competence':
                                return "Seuls les modules, les séquences et les activités peuvent être déposés à la racine d'une compétence";
                            case 'module':
                                return 'Seules les séquences et les activités peuvent être déposées dans un module';
                            case 'sequence':
                                return 'Seules les activités peuvent être déposées dans une séquence';
                            case 'activity':
                                return 'Vous ne pouvez rien déposer sur une activité.';
                            default:
                                return undefined;
                        }
                    }
                }
            } else {
                return undefined;
            }
        } else if (this.assignmentService.getDraggedMode() === 'MOVE') {
            if (!this.canDropContent()) {
                return 'Impossible de déplacer ce contenu ici';
            }
        }
    }

    canDropContent() {
        if (this.assignmentService.getDraggedElement()) {
            if (
                this.assignmentService.getDraggedMode() === 'MOVE' &&
                this.assignmentService.getDraggedElement().desiredPublicationMode !== 'new' &&
                this.assignmentService.getDraggedElement().id === this.draft.id
            ) {
                return false;
            } else {
                return (
                    this.assignmentService.canBeChild(this.draft) &&
                    !this.locked &&
                    !(
                        this.assignmentService.getDraggedElement().hasprice === 2 &&
                        !this.loginService.getUser().roles.nationalAdmin
                    )
                );
            }
        } else {
            return true;
        }
    }

    dropContent(index) {
        if (!this.canDropContent()) {
            return;
        }
        const element = {
            ...this.assignmentService.getDraggedElement()
        };
        if (!this.draft.ordered && element.orderNumber) {
            delete element.orderNumber;
        }
        if (this.assignmentService.getDraggedMode() === 'COPY') {
            this.endDrag();
            if (element.parentid === this.draft.id) {
                if (index === undefined) {
                    index = this.draft.children.length;
                }
                this.updateChildrenPosition(element, index);
            } else {
                this.addChildToDraft(element, index);
            }
        } else if (this.assignmentService.getDraggedMode() === 'MOVE') {
            this.endDrag();
            if (element.parentid === this.draft.id) {
                if (index === undefined) {
                    index = this.draft.children.length;
                }
                this.updateChildrenPosition(element, index);
            } else if (element.parentid) {
                this.subscriptions.add(
                    this.libraryService
                        .getContent(element.parentid)
                        .subscribe((parent: Content) => {
                            if (parent.status !== 'draft') {
                                return;
                            }
                            parent.children = [
                                ...parent.children.filter((child) => child.id !== element.id)
                            ];
                            this.subscriptions.add(
                                this.libraryService
                                    .updateAssembly(parent, undefined)
                                    .subscribe((data: any) => {
                                        this.libraryService.emitDeletedChild(element, parent);
                                        this.addChildToDraft(element, index);
                                    })
                            );
                        })
                );
            } else {
                this.libraryService.emitDeletedChild(element, undefined);
                this.addChildToDraft(element, index);
            }
        }
    }

    addChildToDraft(element, index) {
        const body = JSON.parse(JSON.stringify(this.draft));
        if (index === undefined) {
            index = this.draft.children.length;
        }
        element.position = this.assignmentService.calculatePosition(this.draft, index);
        if (this.draft.ordered) {
            element.orderNumber = 1;
        }
        body.children.push(element);
        this.subscriptions.add(
            this.libraryService.updateAssembly(body, undefined).subscribe(() => {
                this.opened = true;
                if (element.status === 'draft') {
                    if (element.parentid) {
                        element.moved = true;
                        this.libraryService.emitDeletedChild(element, parent);
                    } else {
                        this.libraryService.emitDeletedDraft(element);
                    }
                }
                this.draft.children.push(element);
                this.flashMessageService.flash("Le contenu a été ajouté dans l'assemblage");
            })
        );
    }

    updateChildrenPosition(element, index) {
        const position = this.assignmentService.calculatePosition(this.draft, index, element);
        if (position) {
            this.draft.children.map((data: Content) => {
                if (data.id === element.id) {
                    data.position = position;
                }
                return data;
            });
            this.subscriptions.add(
                this.libraryService.updateAssembly(this.draft, undefined).subscribe(() => {
                    this.flashMessageService.flash('Le contenu a été déplacé');
                })
            );
        }
    }

    confirmOrdo($event: any) {
        this.initialDraft = undefined;
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.libraryService.updateAssembly(this.draft, undefined).subscribe((data: any) => {
                this.editOrder = false;
                this.flashMessageService.flash("L'ordonnancement du contenu a été mis à jour");
            })
        );
    }

    cancelOrdo($event) {
        $event.stopImmediatePropagation();
        this.draft.children.map((child: Content) => {
            this.initialDraft.children.map((initialChild: Content) => {
                if (child.id === initialChild.id) {
                    if (initialChild.orderNumber) {
                        child.orderNumber = initialChild.orderNumber;
                    } else {
                        delete child.orderNumber;
                    }
                }
            });
            return child;
        });
        if (this.initialDraft.ordered) {
            this.draft.ordered = true;
        } else {
            this.draft.ordered = false;
        }
        this.initialDraft = undefined;
        this.editOrder = false;
    }
}
