import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, fromEvent, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { ConfigService } from './config.service';
import { LogService } from './log.service';

import { Structure } from '../structures/structure';

import { CookieService } from 'ngx-cookie-service';
import { EasiHttpParams } from '../interceptors/easi-http-params';
import { User } from '../structures/user';
import { log } from './decorators/log.decorator';

/**
 * Service gérant l'utilisateur actuel
 */
@Injectable({
    providedIn: 'root'
})
export class LoginService {
    /**
     * L'utilisateur actuel
     */
    user: any;
    moderator: Array<number> = [];
    lastStructure: any = {};
    closeAllDialogs$: Subject<any> = new Subject();

    structureListener: any;
    ourApplicationListener: any;

    updateStructure: Subject<any> = new Subject();

    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private logService: LogService,
        private cookieService: CookieService
    ) {
        window.addEventListener(
            'message',
            (event) => {
                if (event.data.structureid) {
                    this.setLastStructure(event.data);
                }
            },
            false
        );
    }

    /**
     * retourne la liste des structures.
     */
    @log()
    getStructures(): Observable<Array<Structure>> {
        const iframe: HTMLIFrameElement = document.getElementById(
            'header-container'
        ) as HTMLIFrameElement;
        iframe.contentWindow.postMessage('getStructures', '*');

        return new Observable((observer) => {
            if (!this.structureListener) {
                this.structureListener = window.addEventListener(
                    'message',
                    (message) => {
                        if (message.data.structures) {
                            observer.next(message.data.structures);
                            observer.complete();
                        }
                    },
                    false
                );
            }
        });
    }

    /**
     * retourne la liste des structures.
     */
    @log() getOurApplication(data: any): Observable<Array<Structure>> {
        const params = {
            type: data.firstname ? 'user' : 'group',
            instanceid: data.id,
            structureid: data.structureid
        };

        const iframe: HTMLIFrameElement = document.getElementById(
            'header-container'
        ) as HTMLIFrameElement;
        iframe.contentWindow.postMessage({ getOurApplication: params }, '*');

        return new Observable((observer) => {
            if (!this.ourApplicationListener) {
                this.ourApplicationListener = window.addEventListener(
                    'message',
                    (message) => {
                        if (message.data.ourapplication) {
                            observer.next(message.data.ourapplication);
                            observer.complete();
                        }
                    },
                    false
                );
            }
        });
    }

    /**
     * Renvoie l'utilisateur actuel
     * @returns {any} Un objet représentant l'utilisateur actuel tel que renvoyé par getCurrentUser
     */
    getUser(): any {
        if (this.user) {
            return this.user;
        }
    }

    /**
     * @returns {any} la derniere structure selectionnee par l'utilisateur.
     */
    getLastStructure(): any {
        return this.lastStructure;
    }

    /**
     * @description modifie l'id de la nom de la derniere structure qui avait ete selectionee par l'utilisateur.
     * @param {any} structure recue via un cookie.
     * @returns {void}
     */
    setLastStructure(structure: any): void {
        if (structure.structureid) {
            this.lastStructure.id = structure.structureid;
            this.lastStructure.name = structure.structurename;
        } else if (structure.id) {
            this.lastStructure.id = structure.id;
            this.lastStructure.name = structure.name;
        }
        this.updateStructure.next(this.lastStructure);
    }

    /**
     * @description update la structure et poste le message a easi-shared.
     * @returns {void}
     */
    updateLastStructure(structure: any): void {
        const iframe: any = document.getElementById('header-container');

        this.setLastStructure(structure);
        iframe.contentWindow.postMessage(
            { setCookies: { structureid: structure.id, structurename: structure.name } },
            '*'
        );
    }

    /**
     * Indique si l'utilisateur actuel est un apprenant
     * @returns {boolean} True si l'utilisateur actuel est un apprenant, faux dans le cas contraire
     */
    isLearner(): boolean {
        if (this.user) {
            return this.user.roles.learner || this.user.roles.prospect;
        }
    }

    /**
     * Indique si l'utilisateur actuel est un apprenant
     * @returns {boolean} True si l'utilisateur actuel est un apprenant, faux dans le cas contraire
     */
    isTutor(): boolean {
        if (this.user) {
            return this.user.roles.tutor;
        }
    }

    /**
     * Indique si l'utilisateur actuel est un administrateur
     * @returns {boolean} True si l'utilisateur actuel est un administrateur, faux dans le cas contraire
     */
    isAdmin(): boolean {
        if (this.user) {
            return this.user.roles.nationalAdmin || this.user.roles.localAdmin;
        }
    }

    /**
     * Indique si l'utilisateur actuel est un administrateur national
     * @returns {boolean} True si l'utilisateur actuel est un administrateur national, faux dans le cas contraire
     */
    isNationalAdmin(): boolean {
        if (this.user) {
            return this.user.roles.nationalAdmin;
        }
    }

    /**
     * Indique si l'utilisateur actuel est un administrateur local
     * @returns {boolean} True si l'utilisateur actuel est un administrateur local, faux dans le cas contraire
     */
    isLocalAdmin(): boolean {
        if (this.user) {
            return this.user.roles.localAdmin;
        }
    }

    /**
     * Renvoie l'utilisateur actuellement connecté sur la plateforme
     * @returns {Observable} Un observable de l'utilisateur actuel
     */
    getCurrent(): Observable<any> {
        const iframe: any = document.getElementById('header-container');
        iframe.contentWindow.postMessage('getCurrent', '*');

        return fromEvent(window, 'message').pipe(
            filter((data: any) => {
                return data.data.current !== undefined;
            }),
            tap(
                (data) => {
                    this.logService.success('LoginService : getCurrent', data);
                },
                (error) => {
                    this.logService.error('LoginService : getCurrent', error);
                    this.isAuthenticated(error);
                }
            )
        );
    }

    /**
     * Renvoie l'utilisateur actuellement connecté sur la plateforme
     * @returns {Observable} Un observable de l'utilisateur actuel
     */
    @log()
    getCurrentUserTraining(): Observable<User> {
        return this.http.get<User>('/user/current').pipe(
            tap(
                (data) => {
                    if (this.cookieService.check('redirect-url')) {
                        const redirectUrl = this.cookieService.get('redirect-url');
                        this.cookieService.delete('redirect-url');
                        window.location.href = redirectUrl;
                        window.location.reload();
                    }
                    this.user = data;
                },
                (error) => {
                    this.isAuthenticated(error);
                }
            )
        );
    }

    @log() getCookies(): Observable<number> {
        return this.http.get<number>(`/getTestCookie`);
    }
    @log() postCookies(): Observable<number> {
        return this.http.post<number>(`/setTestCookie`, undefined);
    }

    /**
     * @param { HttpErrorResponse } error L'erreur renvoyé par les web-services
     * Redirige l'utilisateur sur easi-connect s'il n'est pas connecté
     */
    isAuthenticated(error: HttpErrorResponse) {
        if (
            error.error.errorCode &&
            (error.error.errorCode === 'USER_NOT_AUTH' || error.error.errorCode === 400301)
        ) {
            window.location.href = this.configService.getRedirectEndPoint();
        }
    }

    initCheckConnectedInterval() {
        setInterval(() => {
            // substring pour supprimer la partie /uimmws de l'url car is_still_connected est à la racine du back
            this.http
                .get<boolean>(
                    `${this.configService
                        .getEndPoint()
                        .substring(
                            0,
                            this.configService.getEndPoint().length - 7
                        )}/is_still_connected.php`,
                    {
                        params: new EasiHttpParams({ overrideBaseUrl: true })
                    }
                )
                .subscribe((data: boolean) => {
                    if (data === false) {
                        const iframe: HTMLIFrameElement = document.getElementById(
                            'header-container'
                        ) as HTMLIFrameElement;
                        iframe.contentWindow.postMessage('userDisconnected', '*');
                        this.closeAllDialogs$.next(true);
                    }
                });
        }, 5 * 60 * 1000);
    }
}
